import { useFormik } from "formik";
import { useEffect, useState } from "react";
import ScheduleOperations from "./scheduleOpera";
import ListGroup from "react-bootstrap/ListGroup";
import "./index.css"
import { useDispatch, useSelector } from "react-redux";
import { surprisebagDetails_api, update_schedule_api } from "../../../API/baseUrl";
import { removeDataFromReducer, surprisebagDetails, updateshedule } from "../../../redux/actions";
import ToastMessage from "../../../utils/ToastMessage";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import dayjs from 'dayjs';



const initialValues = {

    mondayProperty: '',
    mondayLimit: '',
    mondayStart: '',
    mondayEnd: '',

    tuesdayProperty: '',
    tuesdayLimit: '',
    tuesdayStart: '',
    tuesdayEnd: '',

    wednesdayProperty: '',
    wednesdayLimit: '',
    wednesdayStart: '',
    wednesdayEnd: '',

    thursdayProperty: '',
    thursdayLimit: '',
    thursdayStart: '',
    thursdayEnd: '',

    fridayProperty: '',
    fridayLimit: '',
    fridayStart: '',
    fridayEnd: '',

    saturdayProperty: '',
    saturdayLimit: '',
    saturdayStart: '',
    saturdayEnd: '',

    sundayProperty: '',
    sundayLimit: '',
    sundayStart: '',
    sundayEnd: '',

}


function Schedule({ id ,shobtn}) {
    const [continueactive, setContinueactive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [viewDaysData, setViewDaysData] = useState([]);
    const dispatch = useDispatch();
    const { surprisebagDetailsData } = useSelector((state) => state?.surprisebagDetailsReduser || {});
    const { updatesheduleData } = useSelector((state) => state?.updatesheduleDataReduser || {});

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
        useFormik({
            initialValues,
            validationSchema: "",
            onSubmit: (value) => submitForm(value)
        });



    const handleformsubmit = (e) => {
        
        e.preventDefault()
        handleSubmit()
    }



    useEffect(() => {
        const parameter = {
            url: `${surprisebagDetails_api}${id}`,
        };
        dispatch(surprisebagDetails(parameter));
    }, []);



    useEffect(() => {
        if (surprisebagDetailsData?.status) {
            setViewDaysData(surprisebagDetailsData?.data.pickUpSchedule)

        } else {
            ToastMessage('error', surprisebagDetailsData?.message);
        }
    }, [surprisebagDetailsData]);


    useEffect(() => {
        if (updatesheduleData?.status) {
            ToastMessage('success', updatesheduleData?.message);
            setLoading(false)
            dispatch(removeDataFromReducer("UPDATE_SHEDULE_RESET"));
        } else {
            ToastMessage('error', updatesheduleData?.message);
            setLoading(false)
        }
    }, [updatesheduleData]);

    function convertTimeToMomentFormat(timeString) {
        return timeString.format();

    }



    const preSelectedOptionForEdit = () => {


        viewDaysData.forEach((element) => {
            const name = element.pickupDay;
            const startTime = element.startTime ? dayjs(element.startTime, 'HH:mm') : '';  
            const endTime = element.endTime ? dayjs(element.endTime, 'HH:mm') : ''; 
            const pickup = element.isPickup;
            const limit = element.dailyNumberOfSurpriseBag ? element.dailyNumberOfSurpriseBag : "";

            if (name === 'Monday') {
                setFieldValue('mondayProperty', pickup);
                setFieldValue('mondayStart', startTime);
                setFieldValue('mondayEnd', endTime);
                setFieldValue('mondayLimit', limit);
            }
            if (name === 'Tuesday') {
                setFieldValue('tuesdayProperty', pickup);
                setFieldValue('tuesdayStart', startTime);
                setFieldValue('tuesdayEnd', endTime);
                setFieldValue('tuesdayLimit', limit);
            }
            if (name === 'Wednesday') {
                setFieldValue('wednesdayProperty', pickup);
                setFieldValue('wednesdayStart', startTime);
                setFieldValue('wednesdayEnd', endTime);
                setFieldValue('wednesdayLimit', limit);
            }
            if (name === 'Thursday') {
                setFieldValue('thursdayProperty', pickup);
                setFieldValue('thursdayStart', startTime);
                setFieldValue('thursdayEnd', endTime);
                setFieldValue('thursdayLimit', limit);
            }
            if (name === 'Friday') {
                setFieldValue('fridayProperty', pickup);
                setFieldValue('fridayStart', startTime);
                setFieldValue('fridayEnd', endTime);
                setFieldValue('fridayLimit', limit);
            }
            if (name === 'Saturday') {
                setFieldValue('saturdayProperty', pickup);
                setFieldValue('saturdayStart', startTime);
                setFieldValue('saturdayEnd', endTime);
                setFieldValue('saturdayLimit', limit);
            }
            if (name === 'Sunday') {
                setFieldValue('sundayProperty', pickup);
                setFieldValue('sundayStart', startTime);
                setFieldValue('sundayEnd', endTime);
                setFieldValue('sundayLimit', limit);
            }
        });
    };



    useEffect(() => {
        preSelectedOptionForEdit();
    }, [viewDaysData]);

    const handleCheckboxChange = (name, e) => {
        setContinueactive(true)
        if (name === 'mondayProperty') {
            //   setCheckOperation(e.target.checked ? true : false);
            //   setMessageOperation(e.target.checked ? true : false);
            setFieldValue('mondayProperty', e.target.checked ? '1' : '0');
            setFieldValue('mondayLimit', 1);

            if (!e.target.checked) {
                setFieldValue('mondayStart', '');
                setFieldValue('mondayEnd', '');
                setFieldValue('mondayLimit', "");
            }
        }

        if (name === 'tuesdayProperty') {
            setFieldValue('tuesdayProperty', e.target.checked ? '1' : '0');
            setFieldValue('tuesdayLimit', 1);
            if (!e.target.checked) {
                setFieldValue('tuesdayStart', '');
                setFieldValue('tuesdayEnd', '');
                setFieldValue('tuesdayLimit', "");
            }
        }
        if (name === 'wednesdayProperty') {
            setFieldValue('wednesdayProperty', e.target.checked ? '1' : '0');
            setFieldValue('wednesdayLimit', 1);
            if (!e.target.checked) {
                setFieldValue('wednesdayStart', '');
                setFieldValue('wednesdayEnd', '');
                setFieldValue('wednesdayLimit', "");
            }
        }
        if (name === 'thursdayProperty') {
            setFieldValue('thursdayProperty', e.target.checked ? '1' : '0');
            setFieldValue('thursdayLimit', 1);
            if (!e.target.checked) {
                setFieldValue('thursdayStart', '');
                setFieldValue('thursdayEnd', '');
                setFieldValue('thursdayLimit', "");
            }
        }
        if (name === 'fridayProperty') {
            setFieldValue('fridayProperty', e.target.checked ? '1' : '0');
            setFieldValue('fridayLimit', 1);
            if (!e.target.checked) {
                setFieldValue('fridayStart', '');
                setFieldValue('fridayEnd', '');
                setFieldValue('fridayLimit', "");
            }
        }
        if (name === 'saturdayProperty') {
            setFieldValue('saturdayProperty', e.target.checked ? '1' : '0');
            setFieldValue('saturdayLimit', 1);
            if (!e.target.checked) {
                setFieldValue('saturdayStart', '');
                setFieldValue('saturdayEnd', '');
                setFieldValue('saturdayLimit', "");
            }
        }
        if (name === 'sundayProperty') {
            setFieldValue('sundayProperty', e.target.checked ? '1' : '0');
            setFieldValue('sundayLimit', 1);

            if (!e.target.checked) {
                setFieldValue('sundayStart', '');
                setFieldValue('sundayEnd', '');
                setFieldValue('sundayLimit', "");
            }
        }
    };


    const handleNewTimeSet = (e, val) => {
        const timeValue = e;

        setFieldValue(val, timeValue);
    };


    const handleinputChange = (name, e) => {
        setContinueactive(true)
        if (name === 'mondayLimit') {
            if (e.target.value < 1) {
                setFieldValue('mondayLimit', 1);
            }
            else {
                setFieldValue('mondayLimit', e.target.value < 6 ? e.target.value : '5');
            }

        }

        if (name === 'tuesdayLimit') {

            if (e.target.value < 1) {
                setFieldValue('tuesdayLimit', 1);
            }
            else {
                setFieldValue('tuesdayLimit', e.target.value < 6 ? e.target.value : '5');
            }
        }
        if (name === 'wednesdayLimit') {

            if (e.target.value < 1) {
                setFieldValue('wednesdayLimit', 1);
            }
            else {
                setFieldValue('wednesdayLimit', e.target.value < 6 ? e.target.value : '5');
            }
        }
        if (name === 'thursdayLimit') {

            if (e.target.value < 1) {
                setFieldValue('thursdayLimit', 1);
            }
            else {
                setFieldValue('thursdayLimit', e.target.value < 6 ? e.target.value : '5');
            }
        }
        if (name === 'fridayLimit') {

            if (e.target.value < 1) {
                setFieldValue('fridayLimit', 1);
            }
            else {
                setFieldValue('fridayLimit', e.target.value < 6 ? e.target.value : '5');
            }
        }
        if (name === 'saturdayLimit') {

            if (e.target.value < 1) {
                setFieldValue('saturdayLimit', 1);
            }
            else {
                setFieldValue('saturdayLimit', e.target.value < 6 ? e.target.value : '5');
            }
        }
        if (name === 'sundayLimit') {

            if (e.target.value < 1) {
                setFieldValue('sundayLimit', 1);
            }
            else {
                setFieldValue('sundayLimit', e.target.value < 6 ? e.target.value : '5');
            }
        }
    };



    // useEffect(() => {
    //     values.tuesdayStart = values.mondayStart
    //     values.tuesdayProperty = values.mondayProperty
    //     values.tuesdayEnd = values.mondayEnd
    //     values.tuesdayLimit = values.mondayLimit


    //     values.wednesdayStart = values.mondayStart
    //     values.wednesdayProperty = values.mondayProperty
    //     values.wednesdayEnd = values.mondayEnd
    //     values.wednesdayLimit = values.mondayLimit


    //     values.thursdayStart = values.mondayStart
    //     values.thursdayProperty = values.mondayProperty
    //     values.thursdayEnd = values.mondayEnd
    //     values.thursdayLimit = values.mondayLimit


    //     values.tuesdayStart = values.mondayStart
    //     values.tuesdayProperty = values.mondayProperty
    //     values.tuesdayEnd = values.mondayEnd
    //     values.tuesdayLimit = values.mondayLimit


    //     values.fridayStart = values.mondayStart
    //     values.fridayProperty = values.mondayProperty
    //     values.fridayEnd = values.mondayEnd
    //     values.fridayLimit = values.mondayLimit


    //     values.saturdayStart = values.mondayStart
    //     values.saturdayProperty = values.mondayProperty
    //     values.saturdayEnd = values.mondayEnd
    //     values.saturdayLimit = values.mondayLimit


    //     values.sundayStart = values.mondayStart
    //     values.sundayProperty = values.mondayProperty
    //     values.sundayEnd = values.mondayEnd
    //     values.sundayLimit = values.mondayLimit


    // }, [values.mondayStart, values.mondayProperty, values.mondayEnd]);

    const getDaysData = () => {
        const operationArray = [];
        operationArray.push({
            pickupDay: 'Monday',
            isPickup: values.mondayProperty ? values.mondayProperty : 0,
            dailyNumberOfSurpriseBag: values.mondayLimit ? values.mondayLimit : 0,
            startTime: values.mondayStart ? values.mondayStart.format('HH:mm') : "",
            endTime: values.mondayEnd ? values.mondayEnd.format('HH:mm') : "",
        });
        // }
        // if (values.tuesdayProperty === 1) {
        operationArray.push({
            pickupDay: 'Tuesday',
            isPickup: values.tuesdayProperty ? values.tuesdayProperty : 0,
            dailyNumberOfSurpriseBag: values.tuesdayLimit ? values.tuesdayLimit : 0,
            startTime: values.tuesdayStart ? values.tuesdayStart.format('HH:mm') : "",
            endTime: values.tuesdayEnd ? values.tuesdayEnd.format('HH:mm') : "",
        });
        // }
        // if (values.wednesdayProperty === 1) {
        operationArray.push({
            pickupDay: 'Wednesday',
            isPickup: values.wednesdayProperty,
            dailyNumberOfSurpriseBag: values.wednesdayLimit,
            startTime: values.wednesdayStart ? values.wednesdayStart.format('HH:mm') : "",
            endTime: values.wednesdayEnd ? values.wednesdayEnd.format('HH:mm') : "",
        });
        // }

        // if (values.thursdayProperty === 1) {
        operationArray.push({
            pickupDay: 'Thursday',
            isPickup: values.thursdayProperty,
            dailyNumberOfSurpriseBag: values.thursdayLimit,
            startTime: values.thursdayStart ? values.thursdayStart.format('HH:mm') : "",
            endTime: values.thursdayEnd ? values.thursdayEnd.format('HH:mm') : "",
        });
        // }
        // if (values.fridayProperty === 1) {
        operationArray.push({
            pickupDay: 'Friday',
            isPickup: values.fridayProperty,
            dailyNumberOfSurpriseBag: values.fridayLimit,
            startTime: values.fridayStart ? values.fridayStart.format('HH:mm') : "",
            endTime: values.fridayEnd ? values.fridayEnd.format('HH:mm') : "",
        });
        // }

        // if (values.saturdayProperty === 1) {
        operationArray.push({
            pickupDay: 'Saturday',
            isPickup: values.saturdayProperty,
            dailyNumberOfSurpriseBag: values.saturdayLimit,
            startTime: values.saturdayStart ? values.saturdayStart.format('HH:mm') : "",
            endTime: values.saturdayEnd ? values.saturdayEnd.format('HH:mm') : "",
        });
        // }

        // if (values.sundayProperty === 1) {
        operationArray.push({
            pickupDay: 'Sunday',
            isPickup: values.sundayProperty,
            dailyNumberOfSurpriseBag: values.sundayLimit,
            startTime: values.sundayStart ? values.sundayStart.format('HH:mm') : "",
            endTime: values.sundayEnd ? values.sundayEnd.format('HH:mm') : "",
        });
        // }
        return operationArray;
    };

    const submitForm = async (value) => {
        console.log("values", value)
        setLoading(true)
        const parameter = {
            surpriseBagIdFk: id,
            pickupSchedule: JSON.stringify(getDaysData()),
            url: update_schedule_api,
        };
        dispatch(updateshedule(parameter));
    }



    return (
        <form onSubmit={handleformsubmit} className="schedule_container">
            <div>
                <h3>Weekly schedule</h3>

                <p>Here you can specify how many Magic Bags will be available to buy in the Pick'n'Treat app per day of the week. You can also change the pick-up window in which customers can come and pick up their Magic Bags in your store.</p>
                <p>If you want to adjust the number of bags offered during the day because you've realised you have more or less food in surplus than anticipated, you can do this in the Dashboard.</p>

            </div>

            <hr></hr>
            <ScheduleOperations {...{
                shobtn,
                values,
                handleCheckboxChange,
                handleNewTimeSet,
                errors,
                setContinueactive,
                handleinputChange,

                viewDaysData
            }} />

            {console.log(viewDaysData, "viewDaysData")}



            <ListGroup.Item>
                {shobtn ?
                 <div style={{ textAlign: "center", margin: "10px" }}>
                 <button className="update_chedule_btn" type='submit'>
                     {loading ? (
                         <CircularProgress size={18} style={{ color: "white" }} />
                     ) : (
                         "Update schedule"
                     )}
                 </button>
             </div> : ""}
               
            </ListGroup.Item>




        </form>
    );
}

export default Schedule;
