

import React, { useEffect, useState } from "react";
import { Box, Modal, Typography, Divider, Grid } from "@mui/material";
import { AiFillMessage } from "react-icons/ai";
import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import ToastMessage from "../../utils/ToastMessage";
import { GetDetailById, ImageUrl } from "../../API/baseUrl";
import { SupportTicketByid } from "../../redux/actions";
import { Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ChatBox from "../../components/ChatByAPI/Index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Chat,
  ConfirmationNumber,
  DateRange,
  Email,
  Info,
  Language,
  Person,
  Phone,
  Subject,
} from "@mui/icons-material";
import ImageIcon from '@mui/icons-material/Image';
import OrderDetailSkeleton from "../orderlist/orderlistSkeleton";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";
import { Textarea } from '@mui/joy/Textarea';

const style = {
  position: "absolute",
  top: "60%",
  right: "0px",
  transform: "translateY(-50%)",
  bgcolor: "none",
  border: "none",
  outline: "none",
  boxShadow: "none",
  p: 2,
  width: "80vw",
  height: "80vh",
  maxWidth: "500px",
  maxHeight: "90vh",
  borderRadius: "8px",
  overflowY: "auto",
};

const TicketDetails = ({ details }) => {
  const navigate = useNavigate();

  const handleOpenImage = (url) => {
    const newWindow = window.open(url);
    if (newWindow) {
      newWindow.focus();
    }
  };

  const checkAvailability = (data) => data || "-";


  const filePath = `${ImageUrl}${checkAvailability(details?.file)}`;
  const isPdf = details?.file?.toLowerCase()?.endsWith('.pdf');

  return (
    <Container
      maxWidth="lg"
      sx={{
        mt: 4,
        mb: 4,
        p: 2,
        bgcolor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: 3,
      }}
    >
      <Box
        sx={{
          p: 3,
          borderRadius: "8px",
          bgcolor: "white",
          boxShadow: 2,
          border: "1px solid #ddd",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: 540,
            fontSize: "22px",
            mb: 2, // Margin bottom
            color: "#333", // Text color
          }}
        >
          <ArrowBackIcon
            onClick={() => navigate(-1)}
            style={{ cursor: "pointer" }}
          />
          Ticket Details
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={6} >
            <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <ConfirmationNumber sx={{ color: "var(--colorused)", mr: 1 }} />
              <strong>Ticket ID: </strong> {details.ticketId}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Info sx={{ color: "var(--colorused)", mr: 1 }} />
              <strong>Status: </strong> {details.supportTicketStatus}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <Typography sx={{ display: "flex", alignItems: "center", mb: 1, flexWrap: "wrap" }}>
              <DateRange sx={{ color: "var(--colorused)", mr: 1 }} />
              <strong>Creation Date: </strong> {details.ticketCreationDate}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <Typography sx={{ display: "flex", alignItems: "center", mb: 1, flexWrap: "wrap" }}>
              <Phone sx={{ color: "var(--colorused)", mr: 1 }} />
              <strong>Mobile Number: </strong>
              {details.countryCode} {details.mobileNumber}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} lg={6}>
            <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Person sx={{ color: "var(--colorused)", mr: 1 }} />
              <strong>User Name: </strong> {details.userName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Email sx={{ color: "var(--colorused)", mr: 1 }} />
              <strong>Email: </strong> {details.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={6}>
            <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <Subject sx={{ color: "var(--colorused)", mr: 1 }} />
              <strong>Purpose: </strong> {details.purpose}
            </Typography>
          </Grid>

          {/* Conditionally Render File */}
          {details?.file && (
            <Grid item xs={12} sm={12} lg={6}>
              <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <ImageIcon sx={{ color: "var(--colorused)", mr: 1 }} />
                <strong style={{ whiteSpace: "nowrap" }}>File: </strong>
                {/* <img
                  height={80}
                  width={80}
                  onClick={() =>
                    handleOpenImage(`${ImageUrl}${checkAvailability(details?.file)}`)
                  }
                  src={`${ImageUrl}${checkAvailability(details?.file)}`}
                  alt="File"
                  style={{ marginLeft: "10px", cursor: "pointer" }}
                /> */}

                {isPdf ? (
                  <a
                    href={filePath}
                    // target="_blank"
                    rel="noopener noreferrer"
                    style={{ cursor: 'pointer', display: 'block', width: '80px', textAlign: 'center' }}
                    onClick={(e) => e.stopPropagation()}
                  >
                    View PDF
                  </a>
                ) : (
                  <img
                    src={filePath}
                    height={80}
                    width={80}
                    alt=""
                    onClick={() => handleOpenImage(filePath)}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </Typography>
            </Grid>
          )}











          {/* Message Section */}
          <Grid item xs={12}>
            <Typography
              sx={{
                display: "flex",
                wordBreak: "break-word", // Ensures text breaks within the container
                overflowWrap: "break-word", // Additional wrapping support
                overflow: "hidden", // Hide overflow if necessary
                textOverflow: "ellipsis", // Add ellipsis if text exceeds container width
                mb: 1,
              }}
            >
              <Chat sx={{ color: "var(--colorused)", mr: 1 }} />
              <strong>Message: </strong>

              {/* <textarea
                readOnly
                value={details.message}
                style={{
                  width: "100%",
                  height: "150px",
                  overflowY: "scroll",
                  resize: "none",
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "4px",
                  marginRight: "10px",
                  backgroundColor:"#e5e5e5"
                }}
              ></textarea> */}

            </Typography>
            <textarea
                readOnly
                value={details.message}
                style={{
                  width: "100%",
                  height: "150px",
                  overflowY: "scroll",
                  resize: "none",
                  border: "1px solid #ccc",
                  padding: "10px",
                  borderRadius: "4px",
                  marginRight: "10px",
                  backgroundColor:"#e5e5e5"
                }}
              ></textarea>
          </Grid>
        </Grid>

      </Box>
    </Container>
  );
};

const TicketDetail = () => {
  const [open, setOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(true); // To control popup display

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { ticketId } = useParams();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(true);
  const { SupportTicketByiddata } = useSelector(
    (state) => state?.SupportTicketByid || {}
  );

  const [ticketDetail, setticketDetail] = useState({});

  useEffect(() => {
    if (SupportTicketByiddata?.status) {
      setticketDetail(SupportTicketByiddata?.data);
      setPending(false)
    } else {
      ToastMessage("error", SupportTicketByiddata?.message);
    }
  }, [SupportTicketByiddata]);

  const ticketdata = () => {
    const parameter = {
      url: `${GetDetailById}${ticketId}`,
    };
    dispatch(SupportTicketByid(parameter));
  };

  useEffect(() => {
    ticketdata();
  }, []);

  return (
    <>
      {pending ? (
        <LoaderAnimation />
      ) : (
        <TicketDetails details={ticketDetail} />
      )}

      <div className="chat-body">
        {showPopup && <div className="popup-message">Chat with us</div>}
        <AiFillMessage
          variant="contained"
          style={{ fontSize: "50px", color: `var(--colorused)` }}
          onClick={handleOpen}
          className="chatBtn"
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={style}>
            <ChatBox
              onClose={handleClose}
              supportTicketIdFk={ticketId}
              ticketId={ticketDetail?.ticketId}
              userIdFk={ticketDetail?.userIdFk}
            />
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default TicketDetail;
