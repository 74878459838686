import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { CircularProgress } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  get_mobile_otp,
  get_resturant_otp,
  restaurant_registration,
  verify_owner_otp,
  verify_resturant_otp,
} from "../../API/baseUrl";
import AppNabvar from "../../components/nabvar";
import imgnum1 from "../../images/184-1843347_2500-flat-icons-vector-set-royalty-free-free.png";
import imgnum3 from "../../images/number-icon-set-18.jpg";
import imgnum2 from "../../images/two.svg";
import {
  createresturant,
  mobileotpaction,
  removeDataFromReducer,
  resturentotpaction,
  verifyOwnerOtp,
  verifyresturantOtp,
} from "../../redux/actions";
import { restaurantCreateshema } from "../../utils/schema";
import ToastMessage from "../../utils/ToastMessage";
import ResturantImage from "../resturantImage";
import Timing from "../resturantType";
import RestaurantInfo from "../resturentinformation";
import "./index.css";
import UseScrollToTop from "../../components/topOpen";
import sucessfully from "../../../src/images/sucessfully.webp";
import { Modal } from "react-bootstrap";

const vendorIdFk = localStorage.getItem("vendorIdFk");

const UiPages = () => {
  const navigate = useNavigate();
  const [lat, setLat] = useState(null);
  const [steps, setSteps] = useState(0);
  UseScrollToTop(steps);
  const containerRef = useRef(null);
  const [smShow, setSmShow] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const [imgUrllogo, setImgUrllogo] = useState("");
  const [image, setImage] = useState("");
  const [logoimage, setLogoimage] = useState("");
  const [radiovalue, setRadiovalue] = useState("");
  const [selectedValues, setSelectedValues] = useState("");
  const [cusinlist, setCusinlist] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [operationArray, setOperationArray] = useState([]);
  const [mobilevalue, setMobilevalue] = useState("");
  const [resturantmobilevalue, setResturantmobilevalue] = useState("");
  const [countrycode, setCountrycode] = useState("");
  const [resturantcountrycode, setResturantcountrycode] = useState("");
  const [mobileotp, setMobileotp] = useState("");
  const [resturantotp, setResturantotp] = useState("");
  const [isverified, setIsverified] = useState(false);
  const [isresturantnumberverified, setIsresturantnumberverified] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [showownerOtpResendbtn, setShowownerOtpResendbtn] = useState(false);
  const [showResOtpResendbtn, setShowResOtpResendbtn] = useState(false);

  const [allstartval, setAllstartval] = useState("");
  const [allendval, setAllendval] = useState("");
  const [digitsArray, setDigitsArray] = useState("");
  const [resturantdigitsArray, setResturantdigitsArray] = useState("");

  // const digitsArray = mobileotp?.toString().split("").map(Number);
  // const resturantdigitsArray = resturantotp?.toString().split("").map(Number);

  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const dispatch = useDispatch();
  const { createresturantdata } = useSelector(
    (state) => state?.createResturant || {}
  );
  const { mobileotpdata } = useSelector(
    (state) => state?.mobileotpreducer || {}
  );
  const { resturantotpdata } = useSelector(
    (state) => state?.resturantotpreducer || {}
  );
  const { ownerotpdata } = useSelector((state) => state?.ownerotpverify || {});
  const { resturantotpverifydata } = useSelector(
    (state) => state?.resturantotpverify || {}
  );
  const [showselectedValues, setShowselectedValues] = useState([]);
  const [activeLines, setActiveLines] = useState(Array(5).fill(false));
  const [selected, setSelected] = useState(null);
  const [addressValue, setAddressValue] = useState("");
  const vendorIdFk = localStorage.getItem("vendorIdFk");

  const daysOfWeek = [
    { id: 1, name: "Monday" },
    { id: 2, name: "Tuesday" },
    { id: 3, name: "Wednesday" },
    { id: 4, name: "Thursday" },
    { id: 5, name: "Friday" },
    { id: 6, name: "Saturday" },
    { id: 7, name: "Sunday" },
  ];
  const [days, setDays] = useState(
    daysOfWeek.map((day) => ({
      ...day,
      checked: false,
      startTime: "",
      endTime: "",
    }))
  );

  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);

  const handleScrollTo = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const initialValues = {
    isRestaurantMobileVerified: 0,
    vendorIdFk: vendorIdFk,
    isRestaurantOwnerMobileVerified: 0,
    restaurantName: "",
    restaurantAddress: "",
    restaurantLandlineNumber: "",
    restaurantLandlineNumberCountryCode: "",
    restaurantOwnerName: "",
    restaurantOwnerEmail: "",
    establishmentType: "",
    cuisinesSpeacility: "",
    operationDay: "",
    foodImage: "",
    restaurantLogo: "",
    restaurantMobileNumber: "",
    restaurantCountryCode: "",
    restaurantOwnerMobileNumber: "",
    restaurantOwnerCountryCode: "",
    restaurantAddressLatitude: "",
    restaurantAddressLongitude: "",

    mondayProperty: 0,
    mondayStart: "",
    mondayEnd: "",

    tuesdayProperty: 0,
    tuesdayStart: "",
    tuesdayEnd: "",

    wednesdayProperty: 0,
    wednesdayStart: "",
    wednesdayEnd: "",

    thursdayProperty: 0,
    thursdayStart: "",
    thursdayEnd: "",

    fridayProperty: 0,
    fridayStart: "",
    fridayEnd: "",

    saturdayProperty: 0,
    saturdayStart: "",
    saturdayEnd: "",

    sundayProperty: 0,
    sundayStart: "",
    sundayEnd: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validateOnBlur: "true",
    validateOnChange: "true",
    validationSchema: restaurantCreateshema,
    onSubmit: (value) => submitForm(value),
  });

  console.log("0000", mobilevalue);

  useEffect(() => {
    const vendorIdFk = localStorage.getItem("vendorIdFk");
    if (digitsArray?.length == 4) {
      const parameter = {
        vendorIdFk: vendorIdFk,
        restaurantOwnerMobileOtp: digitsArray,
        url: verify_owner_otp,
      };
      dispatch(verifyOwnerOtp(parameter));
    }
  }, [digitsArray?.length == 4]);

  useEffect(() => {
    const vendorIdFk = localStorage.getItem("vendorIdFk");
    if (resturantdigitsArray?.length == 4) {
      const parameter = {
        vendorIdFk: vendorIdFk,
        restaurantMobileOtp: resturantdigitsArray,
        url: verify_resturant_otp,
      };
      dispatch(verifyresturantOtp(parameter));
    }
  }, [resturantdigitsArray?.length == 4]);

  useEffect(() => {
    if (mobileotpdata?.status) {
      setMobileotp(mobileotpdata?.otp);
      setShowownerOtpResendbtn(true);
      ToastMessage("success", mobileotpdata?.message);
    } else {
      ToastMessage("error", mobileotpdata?.message);
    }
  }, [mobileotpdata]);

  const getData = () => {
    const operationArray = [];

    if (values.mondayStart) {
      operationArray.push({
        operationDay: "Monday",
        startTime: values.mondayStart.format("HH:mm"),
        endTime: values.mondayEnd.format("HH:mm"),
      });
    }
    if (!values.mondayStart) {
      operationArray.push({
        operationDay: "Monday",
        startTime: "",
        endTime: "",
      });
    }
    if (values.tuesdayStart) {
      operationArray.push({
        operationDay: "Tuesday",
        startTime: values.tuesdayStart.format("HH:mm"),
        endTime: values.tuesdayEnd.format("HH:mm"),
      });
    }
    if (!values.tuesdayStart) {
      operationArray.push({
        operationDay: "Tuesday",
        startTime: "",
        endTime: "",
      });
    }
    if (values.wednesdayStart) {
      operationArray.push({
        operationDay: "Wednesday",
        startTime: values.wednesdayStart.format("HH:mm"),
        endTime: values.wednesdayEnd.format("HH:mm"),
      });
    }
    if (!values.wednesdayStart) {
      operationArray.push({
        operationDay: "Wednesday",
        startTime: "",
        endTime: "",
      });
    }

    if (values.thursdayStart) {
      operationArray.push({
        operationDay: "Thursday",
        startTime: values.thursdayStart.format("HH:mm"),
        endTime: values.thursdayEnd.format("HH:mm"),
      });
    }
    if (!values.thursdayStart) {
      operationArray.push({
        operationDay: "Thursday",
        startTime: "",
        endTime: "",
      });
    }
    if (values.fridayStart) {
      operationArray.push({
        operationDay: "Friday",
        startTime: values.fridayStart.format("HH:mm"),
        endTime: values.fridayEnd.format("HH:mm"),
      });
    }

    if (!values.fridayStart) {
      operationArray.push({
        operationDay: "Friday",
        startTime: "",
        endTime: "",
      });
    }

    if (values.saturdayStart) {
      operationArray.push({
        operationDay: "Saturday",
        startTime: values.saturdayStart.format("HH:mm"),
        endTime: values.saturdayEnd.format("HH:mm"),
      });
    }

    if (!values.saturdayStart) {
      operationArray.push({
        operationDay: "Saturday",
        startTime: "",
        endTime: "",
      });
    }

    if (values.sundayStart) {
      operationArray.push({
        operationDay: "Sunday",
        startTime: values.sundayStart.format("HH:mm"),
        endTime: values.sundayEnd.format("HH:mm"),
      });
    }
    if (!values.sundayStart) {
      operationArray.push({
        operationDay: "Sunday",
        startTime: "",
        endTime: "",
      });
    }
    return operationArray;
  };


  useEffect(() => {
    if (createresturantdata?.status) {
      setLoading(false);
      ToastMessage("success", createresturantdata?.message);
      setSmShow(true);
    } else {
      setLoading(false);
      ToastMessage("error", createresturantdata?.message);
    }
  }, [createresturantdata]);

  useEffect(() => {
    if (resturantotpdata?.status) {
      setResturantotp(resturantotpdata?.otp);
      setShowResOtpResendbtn(true);
      ToastMessage("success", resturantotpdata?.message);
    } else {
      ToastMessage("error", resturantotpdata?.message);
    }
  }, [resturantotpdata]);

  useEffect(() => {
    if (resturantotpverifydata?.status) {
      // ToastMessage('success', resturantotpverifydata?.message);
      setIsresturantnumberverified(true);
      setShowResOtpResendbtn(false);
      values.isRestaurantMobileVerified = 1;
    } else {
      ToastMessage("error", resturantotpverifydata?.message);
    }
  }, [resturantotpverifydata]);

  useEffect(() => {
    if (ownerotpdata?.status) {
      // ToastMessage('success', ownerotpdata?.message);
      setIsverified(true);
      setShowownerOtpResendbtn(false);
      values.isRestaurantOwnerMobileVerified = 1;
    } else {
      ToastMessage("error", ownerotpdata?.message);
    }
  }, [ownerotpdata]);

  useEffect(() => {
    if (!values.restaurantMobileNumber) {
      setIsverified(false);
      values.isRestaurantOwnerMobileVerified = 0;
      values.isRestaurantMobileVerified = 0;
      setIsresturantnumberverified(false)
    }
  }, [values.restaurantMobileNumber]);


  const handlemobileverify = async () => {
    const vendorIdFk = localStorage.getItem("vendorIdFk");

    const parameter = {
      vendorIdFk: vendorIdFk,
      restaurantOwnerMobileNumber: mobilevalue,
      restaurantOwnerCountryCode: countrycode,
      url: get_mobile_otp,
    };

    if (mobilevalue.length === 10) {
      dispatch(mobileotpaction(parameter));
    } else {
      ToastMessage("error", "Mobile number must be of 10 digits");
    }
  };

  const handleresturantverify = async () => {
    const vendorIdFk = localStorage.getItem("vendorIdFk");
    const parameter = {
      vendorIdFk: vendorIdFk,
      restaurantMobileNumber: resturantmobilevalue,
      restaurantCountryCode: resturantcountrycode,
      url: get_resturant_otp,
    };

    if (resturantmobilevalue.length === 10) {
      dispatch(resturentotpaction(parameter));
    } else {
      ToastMessage("error", "Mobile number must be of 10 digits");
    }
    // dispatch(resturentotpaction(parameter));
  };



  useEffect(() => {
    const modifiedDays = days.map(({ name, startTime, endTime }) => ({
      operationDay: name,
      startTime,
      endTime,
    }));
    values.operationDay = JSON.stringify(modifiedDays);
  }, [days]);

  useEffect(() => {
    setFieldValue("cuisinesSpeacility", showselectedValues?.join(","));
    values.cuisinesSpeacility = showselectedValues?.join(",");
  }, [showselectedValues]);

  const handleformSubmit = (e) => {
    e.preventDefault();

    const fd = new FormData();
    console.log(image);
    handleSubmit(fd);
  };

  const submitForm = async (value) => {
    setLoading(true);
    const parameter = {
      data: value,
      url: restaurant_registration,
    };

    dispatch(createresturant(parameter));
  };

  const handlenext = (e) => {
    if (steps === 0) {
      if (
        !values.restaurantName ||
        !values.restaurantOwnerName ||
        !values.restaurantOwnerEmail
      ) {
        ToastMessage("error", "Please fill required fields");
        handleformSubmit(e);
      } else if (
        !values.isRestaurantOwnerMobileVerified ||
        !values.isRestaurantMobileVerified
      ) {
        ToastMessage("error", "Please verify mobile number");
      } else if (!lat) {
        ToastMessage("error", "Please select address");
      } else {
        setSteps(steps + 1);
        containerRef.current.scrollTo(0, 0);
      }
    } else if (steps === 1) {
      if (
        !values.establishmentType ||
        !values.cuisinesSpeacility ||
        !values.operationDay.length
      ) {
        ToastMessage("error", "Please fill required fields");
        handleformSubmit(e);
      } else if (getData()?.length != 7) {
        ToastMessage("error", "Please fill all operation days fields");
      } else {
        setSteps(steps + 1);
        containerRef.current.scrollTo(0, 0);
      }
    } else {
      setSteps(steps + 1);
      containerRef.current.scrollTo(0, 0);
    }
  };

  const handleback = () => {
    setSteps(steps - 1);
    containerRef.current.scrollTo(0, 0);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [steps]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [steps]);

  const handleCloseModal = () => {
    setSmShow(false); // Close the modal
  };

  const handlesuccessbtnclck = () => {
    dispatch(removeDataFromReducer("CREATE_RESTURANT_RESET"));
    dispatch(removeDataFromReducer("VERIFY_RESTURANTOTP_RESET"));
    dispatch(removeDataFromReducer("VERIFY_OWNEROTP_RESET"));
    dispatch(removeDataFromReducer("RESTURANT_OTP_RESET"));
    dispatch(removeDataFromReducer("MOBILE_OTP_RESET"));
    dispatch(removeDataFromReducer("MOBILE_VERIFY_RESET"));
    dispatch(removeDataFromReducer("LOGIN_USER_RESET"));
    dispatch(removeDataFromReducer("SEND_EMAIL_RESET"));
    navigate("/");
  };

  return (
    <form className="main_ui_contaoiner">
      <AppNabvar />
      <div className="outlet_container_registration_page" ref={containerRef}>
        <div className="outlet_container_registration-form">
          <div className="steper_countainer_ui">
            <h3 className="create_ui_steper_heading">Create your restaurant</h3>
            <Divider />
            <div className="steper_content_ui">
              <div style={{}}>
                {[0, 1].map((index) => (
                  <div
                    key={index}
                    className="line_ui"
                    // onClick={() => handleLineClick(index)}
                    style={{
                      cursor: "pointer",
                      backgroundColor: index === steps ? "green" : "#c5c5c5",
                    }}
                  ></div>
                ))}
              </div>
              <div className="">
                <div className="stepes_heading_ui_text">
                  {steps === 2 || steps === 1 ? (
                    <CheckCircleIcon
                      sx={{ color: `var(--colorused)`, marginTop: "5px" }}
                    />
                  ) : (
                    <img src={imgnum1} alt="" className="step_num" />
                  )}

                  <div>
                    <h3 className="step_h">Restaurant Information</h3>
                    <p className="step_p">
                      Restaurant name, address, contact no., owner details
                    </p>
                  </div>
                </div>

                <div className="stepes_heading_ui_text">
                  {steps === 2 ? (
                    <CheckCircleIcon
                      sx={{ color: `var(--colorused)`, marginTop: "5px" }}
                    />
                  ) : (
                    <img src={imgnum2} alt="" className="step_num" />
                  )}

                  <div>
                    <h3 className="step_h">Restaurant Type & Timings</h3>
                    <p className="step_p">
                      Establishment & cuisine type, opening hours
                    </p>
                  </div>
                </div>

                {/* <div className='stepes_heading_ui_text'>
                  <img src={imgnum3} alt="" className='step_num' />
                  <div>
                    <h3 className='step_h'>Upload Images</h3>
                    <p className='step_p'>Menu, restaurant, food images</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="component_countainer_ui">
            {steps === 0 ? (
              <h4 className="main_heading_ui">Restaurant Information</h4>
            ) : (
              ""
            )}
            {steps === 1 ? (
              <h4 className="main_heading_ui">Restaurant Type & Timings</h4>
            ) : (
              ""
            )}

            {steps === 0 ? (
              <RestaurantInfo
                {...{
                  lat,
                  setLat,
                  addressValue,
                  setAddressValue,
                  setFieldValue,
                  selected,
                  setSelected,
                  showResOtpResendbtn,
                  showownerOtpResendbtn,
                  digitsArray,
                  resturantdigitsArray,
                  setDigitsArray,
                  setResturantdigitsArray,
                  errors,
                  resturantotp,
                  mobileotp,
                  isverified,
                  isresturantnumberverified,
                  values,
                  handleChange,
                  touched,
                  handleBlur,
                  setFieldValue,
                  setMobilevalue,
                  setCountrycode,
                  setResturantmobilevalue,
                  handleresturantverify,
                  setResturantcountrycode,
                  handlemobileverify,
                }}
              />
            ) : (
              ""
            )}

            {steps === 1 ? (
              // <Timing  {...{  allendval, setAllendval ,allstartval, setAllstartval, cusinlist, setCusinlist,radiovalue, showselectedValues,selectedOptions, setSelectedOptions, setShowselectedValues, setRadiovalue, errors, values, handleChange, touched, handleBlur, setFieldValue, selectedValues, setSelectedValues }} />

              <Timing
                {...{
                  allendval,
                  setAllendval,
                  allstartval,
                  setAllstartval,
                  cusinlist,
                  setCusinlist,
                  radiovalue,
                  showselectedValues,
                  selectedOptions,
                  setSelectedOptions,
                  setShowselectedValues,
                  setRadiovalue,
                  errors,
                  values,
                  handleChange,
                  touched,
                  handleBlur,
                  setFieldValue,
                  selectedValues,
                  setSelectedValues,
                  days,
                  setDays,
                }}
              />
            ) : (
              ""
            )}

            {/* {steps === 2 ?
              <ResturantImage {...{ errors, imgUrllogo, setImgUrllogo, imgUrl, setImgUrl, values, handleChange, touched, handleBlur, setFieldValue, setLogoimage, setImage }} />

              : ""} */}
          </div>
        </div>
      </div>
      <footer className="outlet_btn_container">
        <div className="outlet_btn_container_inner">
          <button
            disabled={steps === 0}
            className="pre_btn"
            onClick={handleback}
            type="button"
          >
            Go Back
          </button>

          {steps === 1 ? (
            <button
              className="nex_btn"
              type="button"
              onClick={handleformSubmit}
            >
              {loading ? (
                <CircularProgress size={18} style={{ color: "white" }} />
              ) : (
                "Submit"
              )}
            </button>
          ) : (
            <button
              className="nex_btn"
              onClick={(e) => handlenext(e)}
              type="button"
            >
              Next
            </button>
          )}
        </div>
      </footer>
      <div style={{ alignContent: "center", display: "flex" }}>
        <Modal
          size="md"
          show={smShow}
          centered
          backdrop="static"
          onHide={() => setSmShow(false)}
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <div style={{ padding: "20px", textAlign: "center" }}>
          <div
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "105px",  // Set parent height
    margin: "auto",
  }}
>
  <img 
    src="https://media.tenor.com/KO8A-aukqqoAAAAM/social-distancing.gif" 
    style={{
      height: "100%",    // Resize the GIF to the height of the parent
      width: "auto",     // Maintain the aspect ratio
    }} 
  />
</div>


            <h3 className="mt-3 text_modal_popup">Your details have been successfully submitted. Kindly check your inbox for a confirmation of the submission.</h3>
            {/* <p>Our team is reviewing your </p> */}
            <button
              type="button"
              className="okbtn"
              onClick={handlesuccessbtnclck}
            >
              Ok
            </button>
          </div>
        </Modal>
      </div>
      ;
    </form>
  );
};

export default UiPages;
