
import { Grid, Box, TextField, InputAdornment, IconButton, } from '@mui/material';
import './index.css';
import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { otpverify_api, signUp_api } from '../../API/baseUrl';
import { loginUser, sendemail } from '../../redux/actions';
import ToastMessage from '../../utils/ToastMessage';
import OtpInput from 'react-otp-input';
import Logo from './logo';

// import { BaseUrl, VERIFY_OTP_CORPORATE, VERIFY_RESEND_OTP_CORPORATE } from '../../API/BaseUrl';


// ----------------------------------------------------------------------


export default function VerifyOtp() {
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    // const otp = localStorage.getItem('otp');
    const email = localStorage.getItem('email');
    const emailName = localStorage.getItem('emailName');
    const dispatch = useDispatch();
    const { otpverify } = useSelector((state) => state?.sendemail || {});
    const { loginInfo } = useSelector((state) => state?.loginUser || {});
    const vendorIdFk = localStorage.getItem('vendorIdFk');


    useEffect(() => {
        if (otpverify?.status) {
            ToastMessage('success', otpverify?.message);
            navigate('/resturant-registration');
        } else {
            ToastMessage('error', otpverify?.message);
        }
    }, [otpverify]);



    const [timeLeft, setTimeLeft] = useState(60);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
        }, 2000);

        return () => { clearInterval(timer); };
    }, []);

    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;


    const handleresend = () => {
        const parameter = {
            email: email,
            name: emailName,
            url: signUp_api,
        };
        dispatch(loginUser(parameter));
    }


    useEffect(() => {
        if (loginInfo?.status) {
            setTimeLeft(60)
            localStorage.setItem('otp', loginInfo?.otp);
            localStorage.setItem('vendorIdFk', loginInfo?.vendorIdFk);
            ToastMessage('success', loginInfo?.message);
        } else {
            ToastMessage('error', loginInfo?.message);
        }
    }, [loginInfo]);

  
    useEffect(() => {
        const parameter = {
            vendorIdFk: vendorIdFk,
            email: email,
            Otp: otp,
            url: otpverify_api,
        };
        if(otp?.length == 6){
            dispatch(sendemail(parameter));
        }
       
    }, [otp?.length == 6]);


    return (
        <div className='login-container'>
            <div className='login-container_fluid'>
                <Logo/>
                <>
                    <div className="creditcard" id="creditcard">
                        {/* <div className='login-div'>
                            {/* <h3>OTP Verification</h3> */}
                            {/* <CloseIcon sx={{ cursor: "pointer" }} onClick={handleback} /> 
                        </div> */}


                        <h3 className="otpmsg">
                         Check your inbox for OTP
                        </h3>


                        <OtpInput
                            skipDefaultStyles="true"
                            inputStyle = 'otp_input_type'
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                        />

                        <div className="verification">
                            <p className='otpverify_resend'> {minutes === 0 && seconds === 0 ? "" :
                                <p className='otpverify_resend'>
                                    0{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                                </p>
                            }
                            </p>
                    
                            <p className='otpverify_resend_btn'> Not received OTP ?<button onClick={handleresend} className='otpverify_resend_btn' type='button' disabled={minutes !== 0 || seconds !== 0} 
                        
                            style={{
                                cursor: (minutes === 0 && seconds === 0) ? "pointer" : "no-drop",
                                color: (minutes === 0 && seconds === 0) ? "green" : "grey",
                              }}
                            > Resend OTP</button></p>
                      
                      
                        </div>
                        <br />

                    </div>

                </>

            </div>
        </div>
    )
}






















































// import React, { useEffect, useState, useRef } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import CloseIcon from '@mui/icons-material/Close';
// import { sendemail } from '../../redux/actions';
// import ToastMessage from '../../utils/ToastMessage';
// import { otpverify_api } from '../../API/baseUrl';

// const VerifyOtp = () => {
//     const navigate = useNavigate();
//     const dispatch = useDispatch();
//     const [otpInput, setOtpInput] = useState(['', '', '', '', '', '']);
//     const [timeLeft, setTimeLeft] = useState(120); // Adjusted to 120 seconds for timer example
//     const { otpverify } = useSelector((state) => state.sendemail || {});
//     const vendorIdFk = localStorage.getItem('vendorIdFk');
//     const email = localStorage.getItem('email');
//     const otp = localStorage.getItem('otp'); // Assuming this is fetched from localStorage

//     // Countdown timer
//     useEffect(() => {
//         const timer = setInterval(() => {
//             setTimeLeft(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
//         }, 1000);

//         return () => clearInterval(timer);
//     }, []);

//     // Handle OTP input change
//     const handleOtpInputChange = (index, value) => {
//         const newOtpInput = [...otpInput];
//         newOtpInput[index] = value;
//         setOtpInput(newOtpInput);

//         // Auto focus on next input
//         if (index < 5 && value !== '') {
//             otpInputsRef.current[index + 1].focus();
//         }
//     };

//     // Resend OTP function
//     const reSend = () => {
//         const parameter = {
//             vendorIdFk: vendorIdFk,
//             email: email,
//             Otp: otp,
//             url: otpverify_api,
//         };
//         dispatch(sendemail(parameter));
//     };

//     // Handle back function
//     const handleBack = () => {
//         navigate("/signup");
//     };

//     // Refs for OTP inputs
//     const otpInputsRef = useRef([]);

//     // Effect to prefill OTP input fields when `otp` changes
//     useEffect(() => {
//         if (otp) {
//             const otpArray = otp.toString().split('').map(Number);
//             const paddedOtpArray = [...otpArray, '', '', '', '', ''].slice(0, 6); // Ensure array length is 6
//             setOtpInput(paddedOtpArray);
//         }
//     }, [otp]);

//     // Effect to navigate after successful OTP verification
//     useEffect(() => {
//         if (otpverify?.status) {
//             localStorage.setItem('auth', 'true'); // Ensure 'auth' is set as a string 'true'
//             ToastMessage('success', otpverify?.message);
//             setTimeout(() => {
//                 navigate('/resturant-registration');
//             }, 1000); // Navigate after 1 second (adjust as needed)
//         } else {
//             ToastMessage('error', otpverify?.message);
//         }
//     }, [otpverify, navigate]);

//     return (
//         <div className='login-container'>
//             <div className='login-container_fluid'>
//                 <div className="creditcard" id="creditcard">
//                     <div className='login-div'>
//                         <h3>OTP Verification</h3>
//                         <CloseIcon sx={{ cursor: "pointer" }} onClick={handleBack} />
//                     </div>
//                     <h3 className="otpmsg">Check text messages for your OTP</h3>
//                     <div className="otpbox_container">
//                         {otpInput.map((digit, index) => (
//                             <input
//                                 key={index}
//                                 ref={el => otpInputsRef.current[index] = el}
//                                 className="box"
//                                 type="text"
//                                 maxLength="1"
//                                 value={digit}
//                                 onChange={(e) => handleOtpInputChange(index, e.target.value)}
//                                 readOnly={timeLeft === 0 ? true : false} // Make OTP input editable only if timer is running
//                             />
//                         ))}
//                     </div>
//                     <div className="verification">
//                         <p className='otpverify_resend'>
//                             {timeLeft > 0 ? `${Math.floor(timeLeft / 60)}:${(timeLeft % 60 < 10 ? '0' : '') + timeLeft % 60}` : ""}
//                         </p>
//                         <p className='otpverify_resend_btn'>
//                             Not received OTP ?{' '}
//                             <button
//                                 className='otpverify_resend_btn'
//                                 type='button'
//                                 disabled={timeLeft !== 0}
//                                 onClick={reSend}
//                             >
//                                 Resend OTP
//                             </button>
//                         </p>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default VerifyOtp;










