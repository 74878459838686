import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import store from './redux/store';
import { Provider } from 'react-redux';
import Login from './pages/login';
import { ToastContainer } from 'react-toastify';
import VerifyOtp from './pages/login/otp';
import SignUp from './pages/login/signup';
import Home from './pages/home';
import DashboardLayout from './layout/dashboardlayout';
import MultiStepForm from './layout/registrationlayout';
import OwnerDocument from './pages/ownerdocument';
import SetPassword from './pages/login/setPassword';
import DashboardHome from './pages/dashboardHome/dashboardHome';
import CreateSurpriceBag from './pages/createSurpriseBag';
import MainPage from './pages/main';
import SurpriseWelcome from './pages/createSurpriseBag/surprisebagwelcome';
import StepstocreateSurprise from './pages/createSurpriseBag/surpriseSteps';
import UiPages from './pages/uipages';
import DetailSurpriseBag from './pages/surprisedetail';
import Terms from './pages/termsandcondition/terms';
import Policy from './pages/termsandcondition/policy';
import Orderlist from './pages/orderlist';
import UseScrollToTop from './components/topOpen';
import ReSetPassword from './pages/login/ResetPassword';
import ForgetPassword from './pages/login/forgetPassword';
import AddMoreSurpriseBag from './pages/addSurpriseBag';
import CreateTicket from './pages/support';
import Supportlist from './pages/support/supportList';
import CreateOrderList from './pages/orderlist/createorderlist';
import ChatSystem from './components/chatSystem/ChatSystem';
import TicketDetail from './pages/createTicketDetail/Index';
import TicketDetailEmail from './pages/createTicketDetail/detailEmail';
import Homedashboard from './pages/homepage/homedashboard';
import Settings from './pages/settings';
const auth = localStorage.getItem("auth");
const documentupload = localStorage.getItem('documentupload');




const router = createBrowserRouter([
  
  {
    path: "/",
    element: <Login/>,
  },
  {
    path: "/verifyotp",
    element: <VerifyOtp/>,
  },
  {
    path: "/signup",
    element: <SignUp/>,
  },
  // {
  //   path: "/",
  //   element: <MultiStepForm/>,
  // },

  // {
  //   path: "/resturant-registration",
  //   element: <Home/>,
  // }, 
  
  {
    path: "/resturant-registration",
    element:   <UiPages/>,
  },

  {
    path: "/setpassword/:id/:email",
    element: <SetPassword/>,
  },
  {
    path: "/forgetPassword",
    element: <ForgetPassword/>,
  },

  {
    path: "/resetpassword/:id/:email",
    element: <ReSetPassword/>,
  },
  {
    path: "/Pick'n'Treate",
    element: <MainPage/>,
  },
  {
    path: "/Pick'n'Treate/policy",
    element: <Policy/>,
  },
  {
    path: "/Pick'n'Treate/terms",
    element: <Terms/>,
  },
  {
    path: "/PicknTreat/help/:ticketId",
    element: <TicketDetailEmail/>,
  },
  

  
  {
    path: "/dashboard",
    element: <DashboardLayout />,
    children: [
      { element: <Navigate to="dashboard/" />, index: true },
      { path: "app", element: <DashboardHome/>},
      { path: "documents", element: <OwnerDocument/>},
      { path: "surpriceBag", element: <CreateSurpriceBag/> },
      { path: "store/:id/:selectindex/:name", element: <DetailSurpriseBag/> },
      { path: "addmore/bag", element: <AddMoreSurpriseBag/> },
      { path: "orderlist", element: <Orderlist/> },
      { path: "createorderlist/:id", element: <CreateOrderList/> },
      { path: "createTicket", element: <Supportlist/> },
      { path: "help/:ticketId", element: <TicketDetail/> },
      { path: "createTicket/add", element: <CreateTicket/> },
      { path: "homepage", element: <Homedashboard/> },
      { path: "settings", element: <Settings/> },
    ]
  },
 
  {
    path: "/surpriceBag",
    element: <CreateSurpriceBag/>,
    children: [
      { element: <Navigate to="surpriceBag/" />, index: true },
      { path: "createSurprisebag/surpriceBag/create", element: <StepstocreateSurprise/> },

      { path: "createSurprisebag", element: <SurpriseWelcome/> },
    ]
  },
]);




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <RouterProvider router={router}>
    <App />
    </RouterProvider>
    <ToastContainer/>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
