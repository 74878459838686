import {all} from 'redux-saga/effects';
import {
  AddmoreSaga,
  CUSINSLISTSSaga,
  CreateTicketSaga,
  SendEmailSaga,
  activeSurpriseBagSaga,
  bagsizelistSaga,
  banklistSaga,
  createBagSaga,
  createResturantSaga,
  dashboardbaglistSaga,
  documentgetSaga,
  editSurpriseBagSaga,
  editcoverPhotoSaga,
  editlogoSaga,
  getPrivacySaga,
  getStatusSaga,
  getfaqSaga,
  loginSaga,
  mobileotpSaga,
  orderListSaga,
  orderDetailsSaga,
  orderstatusChangeSaga,
  ownerotpverifySaga,
  resturantTypeSaga,
  resturantotpSaga,
  resturantotpverifySaga,
  returantSaga,
  sendemailSaga,
  setPasswordSaga,
  surprisebagDetailsSaga,
  updateInstructionSaga,
  updatecalenderSaga,
  updateoverviewSaga,
  updatesheduleSaga,
  uploadimageSaga,
  vendorloginSaga,
  verifyCodeSaga,
  reservationListSaga,
  getSpecialDaysSaga,
  ExcleDownloadOrderSaga,
  SupportTicketByidSaga,
  submitTicketChatDataSaga,
  GetTicketChatDataSaga,
  pickupwindowDataSaga,
  ChangePasswordSaga,
  dashboardSaga,
  notificationSaga,
  notificationreadSaga,
  notificationcountSaga,
  emptytokenSagas,
} from './Sagafile';


export default function* rootSaga() {
  yield all([
    loginSaga(),
    pickupwindowDataSaga(),
    getSpecialDaysSaga(),
    reservationListSaga(),
    notificationreadSaga(),
    verifyCodeSaga(),
    documentgetSaga(),
    getfaqSaga(),
    getPrivacySaga(),
    CreateTicketSaga(),
    AddmoreSaga(),
    getStatusSaga(),
    SendEmailSaga(),
    editlogoSaga(),
    editcoverPhotoSaga(),
    editSurpriseBagSaga(),
    updatecalenderSaga(),
    sendemailSaga(),
    uploadimageSaga(),
    returantSaga(),
    resturantTypeSaga(),
    CUSINSLISTSSaga(),
    mobileotpSaga(),
    resturantotpSaga(),
    ownerotpverifySaga(),
    resturantotpverifySaga(),
    createResturantSaga(),
    setPasswordSaga(),
    vendorloginSaga(),
    banklistSaga(),
    bagsizelistSaga(),
    createBagSaga(),
    dashboardbaglistSaga(),
    surprisebagDetailsSaga(),
    updateInstructionSaga(),
    updateoverviewSaga(),
    updatesheduleSaga(),
    orderListSaga(),
    dashboardSaga(),
    notificationSaga(),
    orderDetailsSaga(),
    activeSurpriseBagSaga(),
    orderstatusChangeSaga(),
    ExcleDownloadOrderSaga(),
    SupportTicketByidSaga(),
    submitTicketChatDataSaga(),
    GetTicketChatDataSaga(),
    ChangePasswordSaga(),
    notificationcountSaga(),
    emptytokenSagas(),
  ]);
}
