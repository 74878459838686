import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getbagsize,
  removeDataFromReducer,
  surprisebagDetails,
  updateoverview,
} from "../../redux/actions";

import {
  ImageUrl,
  bagsize_list_api,
  surprisebagDetails_api,
  update_overview_api,
  update_cover_page,
} from "../../API/baseUrl";
import Textarea from "@mui/joy/Textarea";
import ToastMessage from "../../utils/ToastMessage";
import { Autocomplete, ModalOverflow } from "@mui/joy";
import ListGroup from "react-bootstrap/ListGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useFormik } from "formik";
import { overviewUpdateSchema } from "../../utils/schema";
import { IconButton, TextField } from "@mui/material";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { styled } from "@mui/system";
import { PopupBody, Button } from "react-bootstrap";
import "./index.css";
import { MdOutlineEdit } from "react-icons/md";
import { IoCameraOutline } from "react-icons/io5";
import SpaIcon from "@mui/icons-material/Spa";
import ModalOverview from "./coverPhotoModal";
import ModalOverviewlogo from "./coverPhotoModal/logoModal";

const initialValues = {
  surpriseBagCategory: "",
  surpriseBagName: "",
  surpriseBagDescription: "",
  // surpriseBagSizeIdFk: "",
  dietaryType: "",
};

const OverviewSurpriseBag = ({ id, shobtn }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { surprisebagDetailsData } = useSelector(
    (state) => state?.surprisebagDetailsReduser || {}
  );
  const { bagsizelistData } = useSelector(
    (state) => state?.bagsizelistreduser || {}
  );
  const { updateoverviewData } = useSelector(
    (state) => state?.updateoverviewReduser || {}
  );
  const auth = localStorage.getItem("auth");
  const [edit, setEdit] = useState(false);
  const [categoryval, setCategoryval] = useState("");
  const [dietryval, setDietryval] = useState("");
  const [data, setData] = useState([]);
  const [bagsizelist, setBagsizelist] = useState([]);

  // *********popup*********
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const restaurantLogo = localStorage.getItem('restaurantLogo');
  const restaurantFoodImage = localStorage.getItem('restaurantFoodImage');



  const handleOpen1 = (event) => {
    setOpen1(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose1 = () => {
    setOpen1(false);
    setOpen2(false);
    setAnchorEl(null);
  };

  const handleOpen2 = (event) => {
    setOpen2(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose2 = () => {
    setOpen2(false);
    setOpen1(false);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditBtn = () => {
    setEdit(!edit);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: overviewUpdateSchema,
    onSubmit: (value) => submitForm(value),
  });

  const handleformsubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const submitForm = () => {
    const parameter = {
      surpriseBagIdFk: id,
      surpriseBagCategory: values.surpriseBagCategory,
      surpriseBagName: data?.surpriseBagName,
      surpriseBagDescription: values.surpriseBagDescription,
      // surpriseBagSizeIdFk: values.surpriseBagSizeIdFk,
      
      surpriseBagRetailPrice: values.surpriseBagRetailPrice,
      surpriseBagSellingPrice: values.surpriseBagSellingPrice,
      discount: values.discount,

      dietaryType: values.dietaryType,
      url: update_overview_api,
    };
    dispatch(updateoverview(parameter));
  };

  useEffect(() => {
    const parameter = {
      url: `${surprisebagDetails_api}${id}`,
    };
    if (updateoverviewData?.status) {
      ToastMessage("success", updateoverviewData?.message);
      dispatch(removeDataFromReducer("UPDATE_OVERVIEW_RESET"));
      setEdit(!edit);

      dispatch(surprisebagDetails(parameter));
    } else {
      ToastMessage("error", updateoverviewData?.message);
    }
  }, [updateoverviewData]);

  useEffect(() => {
    if (bagsizelistData?.status) {
      setBagsizelist(bagsizelistData?.data);
    } else {
      ToastMessage("error", bagsizelistData?.message);
    }
  }, [bagsizelistData]);

  useEffect(() => {
    if (surprisebagDetailsData?.status) {
      setData(surprisebagDetailsData?.data);
      values.surpriseBagCategory =
        surprisebagDetailsData?.data.surpriseBagCategory;
      values.surpriseBagName = surprisebagDetailsData?.data.surpriseBagName;
      values.surpriseBagDescription =
        surprisebagDetailsData?.data.surpriseBagDescription;
      // values.surpriseBagSizeIdFk =
      //   surprisebagDetailsData?.data.surpriseBagSizeIdFk;
      values.dietaryType = surprisebagDetailsData?.data.dietaryType;

    
      values.surpriseBagRetailPrice = surprisebagDetailsData?.data.surpriseBagRetailPrice;
      values.surpriseBagSellingPrice = surprisebagDetailsData?.data.surpriseBagSellingPrice;
      values.discount = surprisebagDetailsData?.data.surpriseBagDiscount;


      setCategoryval(surprisebagDetailsData?.data.surpriseBagCategory);
      setDietryval(surprisebagDetailsData?.data.dietaryType);





    } else {
      ToastMessage("error", surprisebagDetailsData?.message);
    }
  }, [surprisebagDetailsData]);

  useEffect(() => {
    const parameter = {
      url: `${surprisebagDetails_api}${id}`,
    };
    dispatch(surprisebagDetails(parameter));
  }, []);
  const category = [
    { label: "Meals", id: 1 },
    { label: "Bread & Pastries", id: 2 },
    { label: "Groceries", id: 3 },
    { label: "Other", id: 4 },
  ];

  const dietryArray = [
    { label: "Non specified", id: 1 },
    { label: "Vegan", id: 2 },
    { label: "Vegetarian", id: 3 },
    { label: "Non-Vegetarian", id: 4 },
  ];

  const handleradiochange = (e) => {
    values.surpriseBagSizeIdFk = e.target.value;
  };

  useEffect(() => {
    const vendorIdFk = localStorage.getItem("restaurantTypeIdFK");
    const restaurantIdFK = localStorage.getItem("restaurantIdFK");

    const parameter = {
      url: `${bagsize_list_api}${restaurantIdFK}`,
    };
    dispatch(getbagsize(parameter));
  }, []);



  const handledropdownChange = (event, newValue) => {
    values.surpriseBagCategory = newValue.label;
    setCategoryval(newValue);
  };

  const handledietryChange = (event, newValue) => {
    values.dietaryType = newValue.label;
    setDietryval(newValue);
  };

  const open = Boolean(anchorEl);
  const _id = open ? "simple-popup" : undefined;


 
  useEffect(() => {
    const percentageValue = (Number(values.discount) / 100) * values.surpriseBagRetailPrice;
    values.surpriseBagSellingPrice = values.surpriseBagRetailPrice - parseFloat(percentageValue.toFixed(2));
    console.log("percentageValue", percentageValue)
  }, [values.surpriseBagRetailPrice, values.discount]);





  return (
    <div className="dashboardoverview_container_fluid">
      <form
        onSubmit={handleformsubmit}
        className="dashboard_home_main_container"
      >
        <div className="d-flex">
          <h3 className="surprisebag_list_heading">Magic Bag Details</h3>


          {
            shobtn ?
              <>
                {edit === true ? (
                  <p onClick={handleEditBtn} className="overview_edit_btn">
                    cancel
                  </p>
                ) : (
                  <p onClick={handleEditBtn} className="overview_edit_btn">
                    Edit
                  </p>
                )}
              </> : ""
          }


          {/* } */}




        </div>

        <hr></hr>
        <div className="surprise_bag_list_container">
          <div className="overview_img_container">
            <div className="edit"></div>
            {open1 ?
              <ModalOverview
                surpriseBagIdFk={id}
                // updatedDetails ={updatedDetails()}
                open1={open1}
                handleClose={handleClose2}
                heading="Change cover image"
                description="Image requirements: PNG or JPG, 100 × 100 pixels, max. size 10 MB."
                // coverimg={`${ImageUrl}${data?.restaurantLogo}`}
                coverimg={`${ImageUrl}${data?.surpriseBagCoverImage ? data?.surpriseBagCoverImage : data?.restaurantFoodImage}`}
              /> : ""}
            {/* <Button
              aria-describedby={_id}
              type="button"
              onClick={handleClick}
              className="logo_EditButton"
            >
              <MdOutlineEdit sx={{ fontSize: "12px" }} />
            </Button> */}
            <BasePopup
              id={_id}
              open={open}
              anchor={anchorEl}
              // open={open1 || open2}
              anchorEl={anchorEl}
              onClose={handleClose}
            >
              <div className="poupdiv" style={{ fontSize: "16px" }}>
                <div
                  onClick={handleOpen1}
                  style={{ cursor: "pointer" }}
                  className="change_profile"
                >
                  <IoCameraOutline
                    style={{ fontSize: "1.5rem", verticalAlign: "bottom" }}
                  />{" "}
                  Change cover image
                  {/* <ModalOverview
                    surpriseBagIdFk={id}
                    // updatedDetails ={updatedDetails()}
                    open1={open1}
                    handleClose={handleClose2}
                    heading="Change cover image"
                    description="Image requirements: PNG or JPG, 100 × 100 pixels, max. size 10 MB."
                    // coverimg={`${ImageUrl}${data?.restaurantLogo}`}
                    coverimg={`${ImageUrl}${data?.surpriseBagCoverImage ? data?.surpriseBagCoverImage : data?.restaurantFoodImage}`}
                  /> */}
                </div>{" "}
                <hr />
                <div
                  onClick={handleOpen2}
                  style={{ cursor: "pointer" }}
                  className="change_profile"
                >
                  <SpaIcon
                    style={{ fontSize: "1.5rem", verticalAlign: "bottom" }}
                  />{" "}
                  Change store logo
                </div>
                <ModalOverviewlogo
                  id={id}
                  open1={open2}
                  // updatedDetails = {updatedDetails()}
                  handleClose={handleClose2}
                  heading="Change store image"
                  description="Image requirements: PNG or JPG, 100 × 100 pixels, max. size 10 MB."
                  coverimg={`${ImageUrl}${data?.restaurantLogo}`}
                />
              </div>
            </BasePopup>

            <img src={`${ImageUrl}${restaurantFoodImage}`} alt="" />



            <img
              src={`${ImageUrl}${restaurantLogo}`}
              alt=""
              className="logo_img"
            />
          </div>

          <div className="overview_details_inside_contain">
            <div className="m-b-10">
              <h5 className="overview_details_h">Name</h5>
              <p className="overview_details_p">{data?.surpriseBagName}</p>
            </div>

            <div className="m-b-10">
              <h5 className="overview_details_h">Description</h5>

              {edit ? (
                <>
                  {" "}
                  <Textarea
                    minRows={4}
                    name="surpriseBagDescription"
                    placeholder="Description"
                    variant="outlined"
                    value={values.surpriseBagDescription}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (value.length <= 200) {
                        handleChange(e); // Call the original handleChange if within limit
                      }
                    }}
                    onBlur={handleBlur}
                  />
                  <p className="descriptionlimit">
                    {values.surpriseBagDescription.length}/200
                  </p>
                  {errors.surpriseBagDescription &&
                    touched.surpriseBagDescription ? (
                    <p className="form-error">
                      {errors.surpriseBagDescription}
                    </p>
                  ) : null}
                </>
              ) : (
                <p className="overview_details_p">
                  {data?.surpriseBagDescription}
                </p>
              )}
            </div>

            <div className="m-b-10">
              <h5 className="overview_details_h">Category</h5>

              {edit ? (
                <>
                  <Autocomplete
                    placeholder=""
                    name="surpriseBagCategory"
                    options={category}
                    defaultValue={categoryval}
                    onChange={(event, newValue) =>
                      handledropdownChange(event, newValue)
                    }
                    sx={{}}
                  />
                  {errors.surpriseBagCategory && touched.surpriseBagCategory ? (
                    <p className="form-error">{errors.surpriseBagCategory}</p>
                  ) : null}
                </>
              ) : (
                <p className="overview_details_p">
                  {data?.surpriseBagCategory}
                </p>
              )}
            </div>

            <div className="m-b-10">
              <h5 className="overview_details_h">Dietary type</h5>

              {edit ? (
                <>
                  <Autocomplete
                    placeholder=""
                    name="dietaryType"
                    options={dietryArray}
                    defaultValue={dietryval}
                    onChange={(event, newValue) =>
                      handledietryChange(event, newValue)
                    }
                    sx={{}}
                  />
                  {errors.dietaryType && touched.dietaryType ? (
                    <p className="form-error">{errors.dietaryType}</p>
                  ) : null}
                </>
              ) : (
                <p className="overview_details_p">{data?.dietaryType}</p>
              )}
            </div>

            {edit ? (
              <>
               
                           <div className="m-b-10 m-t-5">
                             <h5 className="overview_details_h">Retail Price  *</h5>
               
                             <>
                               <TextField
                                 name="surpriseBagRetailPrice" 
                                 disabled=""
                                 label=""
                                 placeholder=""
                                 value={values.surpriseBagRetailPrice}
                                 variant="outlined"
                                 fullWidth
                                 autoComplete="off"
                                 type="text"
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 sx={{ minWidth: "250px", width:"100" }}
                                 size="small"
                               />
                               {errors.surpriseBagRetailPrice && touched.surpriseBagRetailPrice ? (
                                 <p className="form-error">{errors.surpriseBagRetailPrice}</p>
                               ) : null}
                             </>
                           </div>
               
                           <div className="m-b-10 m-t-5">
                             <h5 className="overview_details_h">Discount in % *</h5>
               
                             <>
                               <TextField
                                 name="discount" 
                                 disabled=""
                                 label=""
                                 placeholder=""
                                 value={values.discount}
                                 variant="outlined"
                                 fullWidth
                                 autoComplete="off"
                                 type="text"
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 sx={{ minWidth: "250px", width:"100" }}
                                 size="small"
                               />
                               {errors.discount && touched.discount ? (
                                 <p className="form-error">{errors.discount}</p>
                               ) : null}
                             </>
                           </div>
               
                           <div className="m-b-10 m-t-5">
                             <h5 className="overview_details_h">Selling Price  *</h5>
               
                             <>
                               <TextField
                                 name="surpriseBagSellingPrice" 
                                 disabled
                                 label=""
                                 placeholder=""
                                 value={values.surpriseBagSellingPrice}
                                 variant="outlined"
                                 fullWidth
                                 autoComplete="off"
                                 type="text"
                                 onChange={handleChange}
                                 onBlur={handleBlur}
                                 sx={{ minWidth: "250px", width:"100" }}
                                 size="small"
                               />
                               {errors.surpriseBagSellingPrice && touched.surpriseBagSellingPrice ? (
                                 <p className="form-error">{errors.surpriseBagSellingPrice}</p>
                               ) : null}
                             </>
                           </div>
              </>
            ) : (

              <>
                <div className="m-b-10">
                  <h5 className="overview_details_h">Retail Price</h5>
                  <p className="overview_details_p">
                    Rs. {values.surpriseBagRetailPrice}
                  </p>
                </div>

                <div className="m-b-10">
                  <h5 className="overview_details_h">Selling Price</h5>
                  <p className="overview_details_p">
                    Rs. {values.surpriseBagSellingPrice}
                  </p>
                </div>

                <div className="m-b-10">
                  <h5 className="overview_details_h">Discount</h5>
                  <p className="overview_details_p">
                    Rs. {values.discount} %
                  </p>
                </div>
              </>
            )}
          </div>
        </div>

        {edit && (
          <ListGroup.Item>
            <div style={{ textAlign: "center", margin: "10px" }}>
              <button className="bttnnn1" type="submit">
                Save changes
              </button>
            </div>
          </ListGroup.Item>
        )}
      </form>
    </div>
  );
};

export default OverviewSurpriseBag;