import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import {
  TextField,
  Box,
  Typography,
  CircularProgress,
  Grid,
  Autocomplete,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../utils/ToastMessage";
import {
  changeOrderStatus,
  removeDataFromReducer,
  verifycodeaction,
} from "../../redux/actions";
import {
  change_orderStatus_api,
  verifyPrivateCode_api,
} from "../../API/baseUrl";
import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import InputField from "../../components/input";
import { orderStatusSchema } from "../../utils/schema";
import { Height } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxWidth: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "6px",
  p: 2,
};

export default function StatusChangeModal({

  privatecode,
  setOpenstatusModal,
  openstatusModal,
  orderId,
  setSearchText,
  orderType,
  orderSize,
  orderQuantity,
  callapi, setCallapi, timeleft, timestart,orderdate
}) {
  const dispatch = useDispatch();
  const { changeOrderstatusData } = useSelector(
    (state) => state?.changeOrderstatusReduser || {}
  );
  const { verifyCodeData } = useSelector(
    (state) => state?.verifyCodeReduser || {}
  );
  const [loading, setLoading] = useState(false);
  const [pickupstart, setPickupstart] = useState(false);
  const [iscodeverify, setIscodeverify] = useState(false);
  const [codeloading, setCodeloading] = useState(false);
  const [codeError, setCodeError] = useState();

  console.log("9090", changeOrderstatusData);

  const initialValues = {
    orderIdFk: orderId,
    orderStatus: "",
    orderPrivateCode: privatecode ? privatecode : "",
  };


  // useEffect(() => {
  //   // Parse JSON string to an object
  //   const pickupDetails = JSON.parse(timestart);
  //   // Check if start time has started
  //   const now = new Date();
  //   const currentHour = now.getHours();
  //   const currentMinute = now.getMinutes();
  //   const [startHour, startMinute] = pickupDetails.startTime
  //     .split(":")
  //     .map(Number);

  //   setPickupstart(
  //     currentHour > startHour || (currentHour === startHour && currentMinute >= startMinute)
  //   );

  //   console.log("pickupstart",  currentHour , startHour )
  // }, [timestart]);


  useEffect(() => {
    const pickupDetails = JSON.parse(timestart);
  
    // Ensure orderdate is converted to a valid format
    const formattedOrderDate = orderdate?.split("/")?.reverse()?.join("-"); // Converts '15/11/2024' to '2024-11-15'
  
    const now = new Date();
    const targetDate = new Date(formattedOrderDate); // Use the formatted date
  
    if (!isNaN(targetDate)) { // Check if targetDate is valid
      const [startHour, startMinute] = pickupDetails?.startTime?.split(":")?.map(Number);
  
      targetDate.setHours(startHour, startMinute, 0, 0);
  
      const isPickupStart = now >= targetDate;
  
      setPickupstart(isPickupStart);
  
      console.log("pickupstart", isPickupStart, now.toISOString(), targetDate.toISOString());
    } else {
      console.error("Invalid orderdate format:", orderdate);
    }
  }, [timestart, orderdate]);
  




  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: "",
    onSubmit: () => submitForm(),
  });

  const handleFormSubmit = (event) => {
    event.preventDefault();
    handleSubmit();
  };

  const submitForm = async () => {
    setLoading(true);
    const parameter = {
      orderIdFk: orderId,
      orderStatus: isClaimed == true ? "Not-Collected" : "Collected",
      url: change_orderStatus_api,
    };
    if (iscodeverify) {
      dispatch(changeOrderStatus(parameter));
    } else if (isClaimed) {
      dispatch(changeOrderStatus(parameter));
    } else {
      ToastMessage("error", "Please verify code");
      setLoading(false);
    }
  };

  const handleverifyCode = async () => {
    setCodeloading(true);
    const parameter = {
      orderIdFk: orderId,
      orderPrivateCode: values.orderPrivateCode,
      url: verifyPrivateCode_api,
    };
    if (values.orderPrivateCode) {
      dispatch(verifycodeaction(parameter));
    } else {
      setCodeError("Please enter code");
      setCodeloading(false);
    }
  };

  useEffect(() => {
    if (verifyCodeData?.status) {
      ToastMessage("success", verifyCodeData?.message);
      setCodeloading(false);
      setIscodeverify(true);
      dispatch(removeDataFromReducer("VERIFY_CODE_RESET"));
    } else {
      setCodeloading(false);
      ToastMessage("error", verifyCodeData?.message);
    }
  }, [verifyCodeData]);

  useEffect(() => {
    if (changeOrderstatusData?.status) {
      setLoading(false);
      ToastMessage("success", changeOrderstatusData?.message);
      setCallapi(!callapi)
      dispatch(removeDataFromReducer("CHANGE_ORDERSTATUS_RESET"));

      dispatch(removeDataFromReducer("VERIFY_CODE_RESET"));
      // setSearchText("")
      setOpenstatusModal(false);
    } else {
      setLoading(false);
      ToastMessage("error", changeOrderstatusData?.message);
      dispatch(removeDataFromReducer("VERIFY_CODE_RESET"));
    }
  }, [changeOrderstatusData]);

  const handleModalClose = () => {
    setOpenstatusModal(false);
  };

  const orderStatuslist = [
    {
      id: 1,
      status: "Collected",
    },
    {
      id: 2,
      status: "Not-Collected",
    },
  ];

  const handlestatuschange = (data, val, type) => {
    setFieldValue("orderStatus", val.status);
  };

  const [isClaimed, setIsClaimed] = useState(false);
  const handleCheckboxChange = (event) => {
    setIsClaimed(event.target.checked);
  };


  return (
    <div>
      <Modal
        open={openstatusModal}
        aria-labelledby="special-day-modal-title"
        aria-describedby="special-day-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleFormSubmit}>
          <Grid container spacing={2} sx={{ display: "flex", p: 3 }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                fontWeight: "bold",
              }}
            >
              <Typography
                variant="h5"
                component="h5"
                sx={{ fontWeight: "bold" }}
              >
                Change Status
              </Typography>
              <CloseIcon
                onClick={handleModalClose}
                sx={{ cursor: "pointer" }}
              />
            </Grid>
      
            <div
              style={{
                display: "flex",

                paddingLeft: "20px",
              }}
              className="mt-2"
            >
              {orderType && <p style={{ marginRight: "8px" }}>{orderType}</p>}

              {orderSize && (
                <span style={{ marginRight: "8px" }}>
                  <span
                    style={{ display: "inline-block", verticalAlign: "middle", marginRight: "8px" }}
                  >
                    •
                  </span>
                  {orderSize}
                </span>
              )}

              {orderQuantity && (
                <span style={{ marginRight: "8px" }}>
                  <span
                    style={{ display: "inline-block", verticalAlign: "middle" }}
                  >
                    •
                  </span>{" "}

                  {orderQuantity} {orderQuantity > 1 ? "Bags" : "Bag"}
                </span>
              )}
            </div>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Grid item xs={10} sm={10} md={10} lg={10} sx={{}}>
                <InputField
                  label="Pickup Code"
                  disabled={iscodeverify}
                  placeholder="Pickup Code"
                  name="orderPrivateCode"
                  value={values.orderPrivateCode}
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              
                {codeError ? <p className="form-error">{codeError}</p> : ""}
              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} sx={{ textAlign: "end" }}>
                {iscodeverify ? (
                  <button disabled className="codeverified_btn" type="button">
                    Verified
                  </button>
                ) : (
                  <button
                    onClick={handleverifyCode}
                    className="codeverify_btn"
                    type="button"
                  disabled={!pickupstart}
                  >
                    {codeloading ? (
                      <CircularProgress size={18} style={{ color: "white" }} />
                    ) : (
                      "Verify"
                    )}
                  </button>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} sx={{}}>

              {
                iscodeverify ? "" :
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={timeleft !== 0}
                        checked={isClaimed}
                        onChange={handleCheckboxChange}
                        style={{ color: " var(--colorused)" }} // Sets the checkbox color to green
                      // Optionally, you can set the checked color
                      />
                    }
                    label="Customer has not claimed the order"
                  />
              }




            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ display: "flex", justifyContent: "end" }}
            >
              {/* <button className="imageupload_btn" type="submit">
                {loading ? (
                  <CircularProgress size={18} style={{ color: "white" }} />
                ) : (
                  "Submit"
                )}
              </button> */}
              <button
                className="imageupload_btn"
                type="submit"
                disabled={!isClaimed && !iscodeverify}
              >
                {loading ? (
                  <CircularProgress size={18} style={{ color: "white" }} />
                ) : isClaimed ? (
                  "Unclaimed"
                ) : (
                  "Claimed"
                )}
              </button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
