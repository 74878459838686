import {combineReducers} from 'redux';
import {
  activeSurpriseBagReduser,
  addMoreReduser,
  bagsizelistreduser,
  bankListreduser,
  createResturant,
  createTicketReduser,
  createbag,
  cusinlist,
  dashboardbaglist,
  editLogoReduser,
  editSurpriseBagReduser,
  editcoverPhotoReduser,
  forgetPasswordReduser,
  getDocumentReduser,
  getPrivacyReduser,
  getStatusReduser,
  getfaqReduser,
  loginUser,
  mobileotpreducer,
  orderListReduser,
  orderDetailsReduser,
  ownerotpverify,
  resturantDetails,
  resturantType,
  resturantotpreducer,
  resturantotpverify,
  sendemail,
  setPasswordreduser,
  surprisebagDetailsReduser,
  updateInstructionReduser,
  updatecalenderReduser,
  updateoverviewReduser,
  updatesheduleDataReduser,
  uploadimagereduser,
  vendorLoginreduser,
  verifyCodeReduser,
  changeOrderstatusReduser,
  reservationListDataReduser,
  getSpecialdaysDataReduser,
  ExcleDownloadOrder,
  SupportTicketByid,
  submitTicketChatData,
  GetTicketChatData,
  pickupwindowDataReduser,
  ChangePasswordReduser,
  dashboardReduser,
  notificationReduser,
  notificationreadReduser,
  notificationcountReduser,
  emptytokenreduser
} from './Reducerfile';
import {LOGOUT} from '../type';
import { changeState } from './CoreReduser';


const rootReducerr = combineReducers({
  loginUser: loginUser,
  reservationListDataReduser: reservationListDataReduser,
  changeOrderstatusReduser: changeOrderstatusReduser,
  verifyCodeReduser: verifyCodeReduser,
  getDocumentReduser: getDocumentReduser,
  getfaqReduser: getfaqReduser,
  getPrivacyReduser: getPrivacyReduser,
  createTicketReduser: createTicketReduser,
  getStatusReduser: getStatusReduser,
  forgetPasswordReduser: forgetPasswordReduser,
  sendemail: sendemail,
  bagsizelistreduser: bagsizelistreduser,
  createbag: createbag,
  addMoreReduser: addMoreReduser,
  uploadimagereduser: uploadimagereduser,
  resturantDetails: resturantDetails,
  resturantType: resturantType,
  cusinlist: cusinlist,
  bankListreduser: bankListreduser,
  mobileotpreducer: mobileotpreducer,
  resturantotpreducer: resturantotpreducer,
  ownerotpverify: ownerotpverify,
  setPasswordreduser: setPasswordreduser,
  vendorLoginreduser: vendorLoginreduser,
  resturantotpverify: resturantotpverify,
  createResturant: createResturant,
  dashboardbaglist: dashboardbaglist,
  updateInstructionReduser: updateInstructionReduser,
  surprisebagDetailsReduser: surprisebagDetailsReduser,
  updateoverviewReduser:updateoverviewReduser,
  updatesheduleDataReduser:updatesheduleDataReduser,
  updatecalenderReduser:updatecalenderReduser,
  orderListReduser:orderListReduser,
  orderDetailsReduser:orderDetailsReduser,
  editcoverPhotoReduser:editcoverPhotoReduser,
  activeSurpriseBagReduser:activeSurpriseBagReduser,
  editSurpriseBagReduser:editSurpriseBagReduser,
  editLogoReduser:editLogoReduser,
  changeState: changeState,
  getSpecialdaysDataReduser: getSpecialdaysDataReduser,
  ExcleDownloadOrder: ExcleDownloadOrder,
  SupportTicketByid: SupportTicketByid,
  submitTicketChatData: submitTicketChatData,
  GetTicketChatData: GetTicketChatData,
  pickupwindowDataReduser:pickupwindowDataReduser,
  ChangePasswordReduser:ChangePasswordReduser,
  dashboardReduser:dashboardReduser,
  notificationReduser:notificationReduser,
  notificationreadReduser:notificationreadReduser,
  notificationcountReduser:notificationcountReduser,
  emptytokenreduser: emptytokenreduser,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return rootReducerr(state, action);
};

export default rootReducer;
