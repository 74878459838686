import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ToastMessage from '../../utils/ToastMessage';
import { GetTicketChatData_api, image_support_chat, ImageUrl, TicketChatData_api } from '../../API/baseUrl';
import { GetTicketChatDataAction, submitTicketChatDataAction } from '../../redux/actions';
import './index.css';
import { Avatar, Box, IconButton, Paper, TextField, Typography, InputAdornment, CircularProgress, Modal } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImagePop from '../ImagePop/Index';
const ChatBox = ({ supportTicketIdFk, userIdFk, ticketId, from, onClose, fromtype }) => {
  const [ChatGetData, setGetChatData] = useState([]);
  const [messageContent, setMessageContent] = useState('');
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const chatMessagesEndRef = useRef(null);
  const submitTicketChatData = useSelector((state) => state?.submitTicketChatData?.submitTicketChatData);
  const getTicketChatData = useSelector((state) => state?.GetTicketChatData?.GetTicketChatData);
  const [SendMessgae, SetSendMEssage] = useState(false)
  const [open, setOpen] = React.useState(false);
  console.log(open, "openopen")
  const [modleImage, setModleImage] = useState('')
  const handleOpen = (image) => {
    setModleImage(image)
    setOpen(true);
  }
  const handleClose = () => {
    setModleImage("")

    setOpen(false);
  }
  const [loading, setLoading] = useState(false)
  console.log(SendMessgae, "SendMessgaeSendMessgae")

  useEffect(() => {
    chatMessagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [ChatGetData]);

  const GetChatData = () => {
    const parameter = {
      ticketId: ticketId,
      url: GetTicketChatData_api,
    };

    dispatch(GetTicketChatDataAction(parameter));
  };


  const ChatData = async () => {
    if (messageContent.trim() === "" && !file) {
      // toast.error("Mess")
      // SetSendMEssage(true)
      return;
    }
    // SetSendMEssage(false)
    setLoading(true)
    const fd = new FormData();
    fd.append('supportTicketIdFk', supportTicketIdFk);
    fd.append('from', String(userIdFk))
    fd.append('fromType', fromtype ? fromtype : 'vendor');
    fd.append('ticketId', ticketId);
    fd.append('content', messageContent ? messageContent : "");
    fd.append('type', file ? 'files' : 'text');

    if (file) {
      fd.append('files', file);
    }

    try {
      const parameter = {
        data: fd,
        url: image_support_chat,
      };
      dispatch(submitTicketChatDataAction(parameter));
      setFile("")
      setMessageContent('');

    } catch (error) {
      ToastMessage('error', 'Failed to send message');
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setMessageContent(value);
    SetSendMEssage(value.trim() !== "");
  };


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      ChatData();
    }
  };

  useEffect(() => {
    GetChatData();
  }, [ticketId]);

  useEffect(() => {
    if (getTicketChatData?.status) {
      setGetChatData(getTicketChatData?.chatData);
      setLoading(false)
    } else if (getTicketChatData) {
      ToastMessage('error', getTicketChatData?.message);
    }
  }, [getTicketChatData]);

  useEffect(() => {
    if (submitTicketChatData?.status) {
      GetChatData();
    } else if (submitTicketChatData) {
      ToastMessage('error', submitTicketChatData?.message);
    }
  }, [submitTicketChatData]);


  const handleAttachFile = () => {
    // Create an input element dynamically to trigger the file dialog
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/*'; 


    fileInput.onchange = (e) => {
      const selectedFile = e.target.files[0];
      if (selectedFile) {
        SetSendMEssage(true)
        setFile(selectedFile);
        console.log('File selected:', selectedFile);
      }
    };
    console.log(file, "file")
    // Trigger the file input click event
    fileInput.click();
  };
  const handleRemoveImgae = () => {
    SetSendMEssage(false)
    setFile("")
  }
  return (
    <Paper elevation={3} sx={{ width: "100%", height: "100%", display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2, borderBottom: '1px solid #eee' }}>
        <Typography variant="h6">Chat Support</Typography>
        <IconButton
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ flex: 1, overflowY: 'auto', p: 2 }}>
        {ChatGetData?.map((chat, index) => {
          const isAdmin = chat?.chatFromType === "admin";
          const fileExtension = chat?.chatContent.split('.').pop()?.toLowerCase();
          const isImage = fileExtension && ["png", "jpg", "jpeg", "gif"].includes(fileExtension);
          const isVideo = fileExtension && ["mp4", "mov", "avi"].includes(fileExtension);
          const isText = chat?.contentType === "text" && chat?.chatContent?.trim() !== "";


          return (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2,
                flexDirection: isAdmin ? 'row' : 'row-reverse',
              }}
            >
              <Avatar alt={chat?.chatFromType} src="/static/images/avatar/1.jpg" />
              <Paper
                elevation={1}
                sx={{
                  p: 1,
                  ml: isAdmin ? 2 : 0,
                  mr: isAdmin ? 0 : 2,
                  backgroundColor: isAdmin ? '#f5f5f5' : 'var(--colorused)',
                  borderRadius: 2,
                  maxWidth: '70%',
                  overflow: 'hidden',
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',
                  overflowWrap: 'break-word',
                }}
              >
                {isText && (
                  <Typography
                    variant="body2"
                    style={{ color: isAdmin ? 'black' : 'white' }}
                  >
                    {chat?.chatContent}
                  </Typography>
                )}
                {isImage && (
                  <img
                    src={`${ImageUrl}${chat?.chatContent}`}
                    alt="Chat Image"
                    style={{ 
                      maxWidth: '100%',
                      borderRadius: '8px',
                      display: 'block',
                      height: "100px"

                    }}
                    onClick={(e) => {
                      handleOpen(`${ImageUrl}${chat?.chatContent}`)
                    }}
                  />
                )}
                {isVideo && (
                  <video
                    src={`${ImageUrl}${chat?.chatContent}`}
                    controls
                    style={{
                      maxWidth: '100%',
                      borderRadius: '8px',
                      display: 'block',
                      height: "200px"
                    }}
                    onError={(e) => console.error("Error loading video:", e)}
                  >
                    Your browser does not support the video tag.
                  </video>
                )}
              </Paper>
            </Box>
          );
        })}
        <div ref={chatMessagesEndRef} />
      </Box>




      <Box sx={{ p: 2 }}>
        {file && (
          <Box style={{ display: "flex", flexDirection: "column", alignItems: "end", maxWidth: '30%' }}>
            <CloseIcon onClick={handleRemoveImgae} />
            {file.type.startsWith('image') ? (
              <img src={URL.createObjectURL(file)} alt="File preview" style={{ maxWidth: '100%', height: 'auto' }} />
            ) : (
              <Typography variant="body2">{file.name}</Typography>
            )}
          </Box>
        )}
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box >
          <ImagePop src src={modleImage}    onClose={handleClose}/>

        </Box>
      </Modal>



      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          // p: 2,
          paddingLeft: 2,
          paddingRight: 2,
          paddingBottom: 2,
          borderTop: '1px solid #eee',
        }}
      >
        <TextField
          fullWidth
          value={messageContent}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Type a message"
          variant="outlined"
          size="small"
          disabled={Boolean(file)}

          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  disabled={Boolean(messageContent)}

                  onClick={handleAttachFile} style={{ padding: 2 }}>
                  <AttachFileIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <IconButton
          onClick={ChatData}

          style={{
            color: "var(--colorused)",
            cursor: SendMessgae ? "pointer" : "not-allowed"
          }} >
          {loading ? (
            <CircularProgress style={{ color: "var(--colorused)" }} />
          ) :
            (
              <SendIcon />
            )}


        </IconButton>
      </Box>
    </Paper >

  );
};

export default ChatBox;
