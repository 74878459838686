import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ExcleDownloadOrder,
  orderlistaction,
  removeDataFromReducer,
} from "../../redux/actions";
import Rating from '@mui/material/Rating';
import {
  ExcelDownloader_api,
  ImageUrl,
  orderList_api,
} from "../../API/baseUrl";
import ToastMessage from "../../utils/ToastMessage";
import { Box, Card, Container, Stack, Skeleton } from "@mui/material"; // Import Skeleton
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import ClearIcon from "@mui/icons-material/Clear";
import StatusChangeModal from "./statusModal";
import moment from "moment/moment";
import ExcelDownloader from "../../components/excelDownloader";
import { TableSk } from "../../utils/tableSk";
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

const Orderlist = () => {
  const documentStatus = localStorage.getItem("documentStatus");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [openstatusModal, setOpenstatusModal] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState("DESC");
  const [sortByColumnName, setSortByColumnName] = useState("");
  const [orderId, setOrderId] = useState("");
  const [orderType, setOrderType] = useState("");
  const [orderSize, setOrderSize] = useState("");
  const [orderQuantity, setOrderQuantity] = useState("");
  const [callapi, setCallapi] = useState(false);

  const [typeforlist, setTypeforlist] = useState("Accepted");
  const [startvalue, setStartvalue] = useState("");
  const [endvalue, setEndvalue] = useState("");
  const [privatecode, setPrivatecode] = useState("");
  const [timeleft, setTimeleft] = useState("");
  const [timestart, setTimestart] = useState("");
  const [orderdate, setOrderdate] = useState("");
  const notification = useSelector(state => state.notification);
  
  const { orderListData } = useSelector(
    (state) => state?.orderListReduser || {}
  );
  const { ExcleDownloadOrderInfo } = useSelector(
    (state) => state?.ExcleDownloadOrder || {}
  );
  const resturantId = localStorage.getItem("restaurantIdFK");

  useEffect(() => {

    if (orderListData) {
      setPending(true); // Set skeleton to true initially
  
      const timer = setTimeout(() => {
        setPending(false); // Set skeleton to false after 2 seconds
  
        if (orderListData?.status) {
          setData(orderListData?.data);
          setTotalRows(orderListData?.totalRows);
    
        } else {
          ToastMessage("error", orderListData?.message);
          setPending(false);
        }
      }, 500); // 2 seconds delay
  
      // Clear timeout if the component is unmounted or data changes
      return () => clearTimeout(timer);
    }

  }, [orderListData,notification]);

console.log("orderListDataaa", orderListData)



  useEffect(() => {
    setPending(true);
    const parameter = {
      offset,
      limit,
      orderStatus: typeforlist,
      search: searchText,
      columnName: sortByColumnName,
      sortBy: sortBy.toUpperCase(),
      startDate: startvalue ? startvalue.format("DD-MM-YYYY") : "",
      endDate: endvalue ? endvalue.format("DD-MM-YYYY") : "",
      url: `${orderList_api}${resturantId}`,
    };
    dispatch(orderlistaction(parameter));
  }, [
    offset,
    limit,
    callapi,
    searchText,
    typeforlist,
    sortByColumnName,
    sortBy,
    startvalue,
    endvalue,
  ]);

  const handleNavigate = (orderIdFK) => {
    navigate(`/dashboard/createorderlist/${orderIdFK}`);
  };

  useEffect(() => {
    document.title = "Orders";
  }, []);

  const columns = [
    {
      name: "Id",
      width: "250px",
      selector: (row) => (
        <div
          className="column_id"
          onClick={() => handleNavigate(row?.orderId)}
          style={{ cursor: "pointer", color: "blue" }}
        >
          {row?.orderId || "-"}
        </div>
      ),
      sortable: true,
      column_name: "orderId",
      reorder: true,
    },
    {
      name: "Action",
      width: "300px",
      selector: (row) => (
        <div className="status_div_change">
          <p className="order_status_text">
            {row.orderStatus ? row?.orderStatus : "Pending"}
          </p>
          {row.orderStatus === "Collected" ||
            row.orderStatus === "Not-Collected" ? (
            ""
          ) : (
            <button
              onClick={() =>
                handleChangeStatus(
                  row?.orderIdFK,
                  row?.surpriseBagCategory,
                  row?.surpriseBagSize,
                  row?.orderQuantity,
                  row?.orderPrivateCode,
                  row?.minutesRemainingToClose,
                  row?.pickupDetailsInJson,
                  row?.orderDeliveryDate
                )
              }
            >
              Change Status
            </button>
          )}
        </div>
      ),
      sortable: true,
      column_name: "orderStatus",
      reorder: true,
    },
    {
      name: "Type",
      selector: (row) =>
        row.surpriseBagCategory ? row?.surpriseBagCategory : "---",
      sortable: true,
      width: "300px",
      column_name: "surpriseBagCategory",
      reorder: true,
    },
    {
      name: "Size",
      selector: (row) => (row.surpriseBagSize ? row?.surpriseBagSize : "---"),
      sortable: true,
      width: "300px",
      column_name: "surpriseBagSize",
      reorder: true,
    },
    {
      name: "Price",
      selector: (row) =>
        `₹${row.totalOrderAmount ? row?.totalOrderAmount : "---"}`,
      sortable: true,
      width: "250px",
      column_name: "totalOrderAmount",
      reorder: true,
    },
    {
      name: "Quantity",
      selector: (row) => `${row.orderQuantity ? row?.orderQuantity : "---"}`,
      sortable: true,
      width: "250px",
      column_name: "orderQuantity",
      reorder: true,
    },
    ...(typeforlist === "Collected"
      ? [
        {
          name: "Rating",
          selector: (row) =>
            <Stack spacing={1}>
              <Rating name="read-only" value={getIntegerRating(row?.orderRating)} readOnly />
            </Stack>,
        sortable: true,
        width: "250px",
        column_name: "orderRating",
        reorder: true,
          },
        ]
      : []),
{
  name: "Date & Time",
    selector: (row) => (row.orderCreatedAt ? row.orderCreatedAt : "---"),
      sortable: true,
        column_name: "OrderDateTime",
          reorder: true,
            width: "250px",
    },
  ];


  function getIntegerRating(orderRating) {
    return parseInt(orderRating);
}


const customStyles = {
  headCells: {
    style: {
      fontSize: "16px",
      fontWeight: 500,
    },
  },
};

const handlePerRowsChange = async (newLimit, offset) => {
  setOffset(offset);
  setLimit(newLimit);
};

const handlePageChange = (offset) => {
  setOffset(offset);
};

const handleChangeStatus = (
  id,
  surpriseBagCategory,
  surpriseBagSize,
  orderQuantity,
  code,time, timestart,date
) => {
  setOpenstatusModal(true);
  setOrderId(id);
  setOrderType(surpriseBagCategory);
  setOrderSize(surpriseBagSize);
  setOrderQuantity(orderQuantity);
  setPrivatecode(code);
  setTimeleft(time);
  setTimestart(timestart);
  setOrderdate(date);
};

const handleSort = async (column, sortDirection) => {
  setSortBy(sortDirection);
  setSortByColumnName(column.column_name || "");
};

const handleClearSearch = () => {
  setSearchText("");
};

const handleType = (type) => {
  setTypeforlist(type);
};

// Callback for search text
const callBack = (data) => {
  setSearchText(data);
};

const handleExcel = () => {
  const params = {
    startDate: startvalue ? startvalue.format("DD-MM-YYYY") : "",
    endDate: endvalue ? endvalue.format("DD-MM-YYYY") : "",
    url: `${ExcelDownloader_api}${resturantId}`,
  };
  dispatch(ExcleDownloadOrder(params));
};

useEffect(() => {
  if (ExcleDownloadOrderInfo) {
    const blob = new Blob([ExcleDownloadOrderInfo], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "orderlist.xlsx";
    link.click();
    dispatch(removeDataFromReducer("Excle_Download_RESET"));
  } else if (ExcleDownloadOrderInfo?.status === false) {
    ToastMessage(
      "error",
      ExcleDownloadOrderInfo.message ||
      "Failed to download Excel file. Please try again."
    );
  }
  // setPending(false);
}, [ExcleDownloadOrderInfo]);

return (
  <Box sx={{ overflow: "hidden" }}>
    {documentStatus == 2 || documentStatus == 0 ? (
      <div className="alert_message">
        <h1>Please upload your documents to move forward</h1>
      </div>
    ) : (
      <Container maxWidth="false" className="containerdivuser">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="page_heading_div"
        >
          <h3 className="table_heading">Order List</h3>
          <div className="search-field">
            <input
              type="text"
              placeholder="Search"
              style={{ outline: "none" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />

            {searchText ? (
              <ClearIcon
                onClick={handleClearSearch}
                sx={{
                  color: `var(--colorused)`,
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              />
            ) : null}
          </div>
        </Stack>

       



  <>
   <ExcelDownloader
          showdatefilter="true"
          callBack={callBack}
          value={searchText}
          margin="0px"
          handleExcel={handleExcel}
          setSearchtext={setSearchText}
          {...{ startvalue, endvalue, setStartvalue, setEndvalue }}
        />
  
  <Card className="Table_container">
  <Tabs style={{ }}>
    <TabList className="TabList">
      <Tab className="Tab" onClick={() => handleType("Accepted")}>
        Pending
      </Tab>
      <Tab className="Tab" onClick={() => handleType("Collected")}>
        Collected
      </Tab>
      <Tab
        className="Tab"
        onClick={() => handleType("Not-Collected")}
      >
        Not Collected
      </Tab>
    </TabList>
    <TabPanel>
      <div className="m-t-10">
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data}
            dataAlign="center"
            fixedHeader
            progressPending={pending}
            progressComponent={<LoaderAnimation/>}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            fixedHeaderScrollHeight="58vh"
            sortServer
            responsive
            onSort={handleSort}
          />
        {/* )} */}
      </div>
    </TabPanel>

    <TabPanel>
      <div className="m-t-10">
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data}
            dataAlign="center"
            fixedHeader
            progressPending={pending}
            progressComponent={<LoaderAnimation/>}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            fixedHeaderScrollHeight="58vh"
            sortServer
            responsive
            onSort={handleSort}
          />
          {/* )} */}
      </div>
    </TabPanel>
    <TabPanel>
      <div className="m-t-10">
          <DataTable
            customStyles={customStyles}
            columns={columns}
            data={data}
            dataAlign="center"
            fixedHeader
            progressPending={pending}
            progressComponent={<LoaderAnimation/>}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            fixedHeaderScrollHeight="70vh"
            sortServer
            responsive
            onSort={handleSort}
          />
          {/* )} */}
      </div>
    </TabPanel>
    {/* </>
      )} */}
  </Tabs>
  {openstatusModal && (
    <StatusChangeModal
      privatecode={privatecode}
      timestart={timestart}
      orderdate={orderdate}
      {...{
        setOpenstatusModal,
        openstatusModal,
        orderId,
        timeleft,
        setSearchText,
        orderType,
        orderSize,
        orderQuantity,
        callapi,
        setCallapi,
      }}
    />
  )}
</Card>
  </>
 


      </Container>
    )}
  </Box>
);
};

export default Orderlist;
