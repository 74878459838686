import { Button, Container, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ImageUrl, order_details_api } from "../../API/baseUrl";
import { orderdeatilsaction } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ToastMessage from "../../utils/ToastMessage";
import { Box, Grid, Stack } from "@mui/material";
import StatusChangeModal from "./statusModal";
import moment from "moment";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OrderDetailSkeleton from "./orderlistSkeleton";
import Rating from '@mui/material/Rating';
import LoaderAnimation from "../../logo-2/loader/loaderanimation";

function CreateOrderList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState();
  const [orderId, setOrderId] = useState("");
  const [openstatusModal, setOpenstatusModal] = useState(false);
  const [callapi, setCallapi] = useState(false);
  const [isTimePassed, setIsTimePassed] = useState(false);
  const [pending, setPending] = useState(true);
  const { orderdetailsData } = useSelector(
    (state) => state?.orderDetailsReduser || {}
  );

  useEffect(() => {
    if (data?.pickupDetailsInJson) {
      // Parse the JSON string from the API response
      const pickupDetails = JSON.parse(data?.pickupDetailsInJson);

      // Extract endTime from pickupDetails
      const { endTime } = pickupDetails;

      // Get today's date and set its time to the extracted endTime
      const [endHour, endMinute] = endTime.split(":").map(Number);
      const endDateTime = new Date();
      endDateTime.setHours(endHour, endMinute, 0, 0);

      // Get current time
      const currentTime = new Date();

      // Check if the current time is past the end time
      if (currentTime > endDateTime) {
        setIsTimePassed(true);
      }
    }
  }, [data?.pickupDetailsInJson]);

  useEffect(() => {
    if (orderdetailsData?.status) {
      setData(orderdetailsData?.data);
      setPending(false)
    } else {
      ToastMessage("error", orderdetailsData?.message);
    }
  }, [orderdetailsData]);



  const checkAvailability = (data) => data || "-";




  const detailapi = () => {
    const parameter = {
      orderId: id,
      url: order_details_api,
    };
    dispatch(orderdeatilsaction(parameter));
  };

  useEffect(() => {
    detailapi()
  }, [id , callapi]);

  const checkAvailability1 = (value) => {
    return value === 0 ? "No" : value === 1 ? "Yes" : "unknown";
  };


  function getFormattedPickupDetails(pickupDetailsInJson) {
    let pickupDetails;

    // Check if the input is a string and parse it; otherwise, use it directly if it's an object
    if (typeof pickupDetailsInJson === "string") {
      try {
        pickupDetails = JSON.parse(pickupDetailsInJson);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return null; // Return null or an empty string if parsing fails
      }
    } else if (typeof pickupDetailsInJson === "object") {
      pickupDetails = pickupDetailsInJson;
    } else {
      return null; // Return null if the input is neither a string nor an object
    }

    // Format the pickup details
    if (pickupDetails && pickupDetails.pickupDay && pickupDetails.startTime && pickupDetails.endTime) {
      return `${pickupDetails.startTime} to ${pickupDetails.endTime}`;
    } else {
      return null; // Return null if required fields are missing
    }
  }

  function getFormattedPickupday(pickupDetailsInJson) {
    let pickupDetails;

    // Check if the input is a string and parse it; otherwise, use it directly if it's an object
    if (typeof pickupDetailsInJson === "string") {
      try {
        pickupDetails = JSON.parse(pickupDetailsInJson);
      } catch (error) {
        console.error("Error parsing JSON:", error);
        return null; // Return null or an empty string if parsing fails
      }
    } else if (typeof pickupDetailsInJson === "object") {
      pickupDetails = pickupDetailsInJson;
    } else {
      return null; // Return null if the input is neither a string nor an object
    }

    // Format the pickup details
    if (pickupDetails && pickupDetails.pickupDay && pickupDetails.startTime && pickupDetails.endTime) {
      return `${pickupDetails.pickupDay} `;
    } else {
      return null; // Return null if required fields are missing
    }
  }


  const handleChangeStatus = (id) => {
    setOpenstatusModal(true);
    setOrderId(id);
  };

  const formatOrderDate = (date) => {
    return date ? moment(date).format("MMMM Do YYYY, h:mm:ss a") : "---";
  };

  function getIntegerRating(orderRating) {
    return parseInt(orderRating);
}

  return (

    <>
      {pending ? <LoaderAnimation/>
        :
        <div>
          <Container>



            <h5>  <ArrowBackIcon onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }} /> Order Id - <span className="span_id">#{data?.orderId}</span></h5>

            <p className="status_p">
              {checkAvailability(data?.orderStatus)}

              {data?.orderStatus == "Collected" || data?.orderStatus == "Not-Collected" ? "" :
                <p onClick={() => handleChangeStatus(data?.orderIdFK)}>
                  (Change Status)
                </p>
              }
            </p>



            {data?.orderStatus == "Collected" ?

              <p className="status_p">
               Rating
               <Rating name="read-only" value={getIntegerRating(data?.orderRating)} readOnly />
              </p>

              :
              ""
            }

            <div className="main_div_design">
              <Grid spacing={4}>
                <Grid className="support_section_view" item xs={12} md={6}>
                  <div className="view_membership_field">
                    <b>Magic Bag Category : </b>
                    <p className="viewpagevalues">
                      {checkAvailability(data?.surpriseBagCategory)}
                    </p>
                  </div>
                </Grid>


                <Grid className="support_section_view" item xs={12} md={6}>
                  <div className="view_membership_field">
                    <b>Order Quantity : </b>
                    <p className="viewpagevalues">
                      {checkAvailability(data?.orderQuantity)}
                    </p>
                  </div>
                </Grid>

                {/* <Grid className="support_section_view" item xs={12} md={6}>
        <div className="view_membership_field">
          <b>Order Size : </b>
          <p className="viewpagevalues">
            {checkAvailability(data?.size)}
          </p>
        </div>
      </Grid> */}




                {/* order details */}



                <Grid className="support_section_view" item xs={12} md={6}>
                  <div className="view_membership_field">
                    <b>Total Order Amount: </b>
                    <p className="viewpagevalues">
                      {/* {checkAvailability(data?.totalOrderAmount)} */}
                      {checkAvailability(`₹${data?.totalOrderAmount}`)}

                    </p>
                  </div>
                </Grid>

                <Grid className="support_section_view" item xs={12} md={6}>
                  <div className="view_membership_field">
                    <b>Time of order</b>
                    <p className="viewpagevalues">

                      {data?.orderCreatedAt}

                    </p>
                  </div>
                </Grid>


                {/* <Grid className="support_section_view" item xs={12} md={6}>
        <div className="view_membership_field">
          <b>Time of pickup</b>
          <p className="viewpagevalues">

          {data?.orderCreatedAt}
              
          </p>
        </div>
      </Grid> */}


                <Grid className="support_section_view" item xs={12} md={6}>
                  <div className="view_membership_field">
                    <b>PickUp Day : </b>
                    <p className="viewpagevalues">
                      {getFormattedPickupday(data?.pickupDetailsInJson)}
                    </p>
                  </div>
                </Grid>

                <Grid className="support_section_view" item xs={12} md={6}>
                  <div className="view_membership_field">
                    <b>PickUp Time: </b>
                    <p className="viewpagevalues">
                      {getFormattedPickupDetails(data?.pickupDetailsInJson)}
                    </p>
                  </div>
                </Grid>







                {/*           
      <Grid className="support_section_view" item xs={12} md={6}>
        <div className="view_membership_field">
          <b>Order Rated : </b>
          <p className="viewpagevalues">
            {checkAvailability1(data?.isOrderRated)}
          </p>
        </div>
      </Grid>


      <Grid className="support_section_view" item xs={12} md={6}>
        <div className="view_membership_field">
          <b>Order Rating : </b>
          <p className="viewpagevalues">
            {checkAvailability(data?.orderRating)}
          </p>
        </div>
      </Grid>
    
      
      <Grid className="support_section_view" item xs={12} md={6}>
        <div className="view_membership_field">
          <b>Date & Time </b>
          <p className="viewpagevalues">

          {data?.orderCreatedAt}
              
          </p>
        </div>
      </Grid> */}



              </Grid>
            </div>

            {openstatusModal ? (
              <StatusChangeModal
                privatecode={data?.orderPrivateCode}
                timeleft={data?.minutesRemainingToClose}
                timestart={data?.pickupDetailsInJson}
                orderdate={data?.orderDeliveryDate}
                function={detailapi}
                {...{
                  setOpenstatusModal,
                  openstatusModal,
                  orderId,
                  callapi, setCallapi
                }}
              />
            ) : (
              ""
            )}


          </Container>
        </div>
      }
    </>



  );
}

export default CreateOrderList;

