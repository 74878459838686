import React, { useEffect, useState } from "react";
import { Col, Dropdown, Form, Row } from "react-bootstrap";
import { bagsize_list_api } from "../../API/baseUrl";
import { getbagsize } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import ToastMessage from "../../utils/ToastMessage";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import "./index.css";
import { Skeleton, TextField } from "@mui/material";

const BagSizeListComp = ({
  touched,
  values,
  errors,
  handleBlur,
  handleChange,
  id,
  setContinueactive,
  setShowRecommendation,
  selectedCard,

}) => {

  const dispatch = useDispatch();
  const { bagsizelistData } = useSelector(
    (state) => state?.bagsizelistreduser || {}
  );
  const [data, setData] = useState([]);
  const vendorIdFk = localStorage.getItem("restaurantTypeIdFK");
  const restaurantIdFK = localStorage.getItem("restaurantIdFK");
  const [BagsizeLoading, setBagsizeLoading] = useState(false)
  console.log(BagsizeLoading, "BagsizeLoadingBagsizeLoading")
  console.log(BagsizeLoading, "BagsizeLoadingBagsizeLoading")
  const handleradiochange = (e) => {
    values.surpriseBagSizeIdFK = e.target.value;
    setContinueactive(true);
  };

  // useEffect(() => {
  //   if (bagsizelistData?.status) {
  //     setData(bagsizelistData?.data);
  //   } else {
  //     ToastMessage("error", bagsizelistData?.message);
  //   }
  // }, [bagsizelistData]);


  useEffect(() => {

    console.log(bagsizelistData, "aaaassssssdddd")
    if (bagsizelistData?.status) {
      setBagsizeLoading(false)
      setData(bagsizelistData?.data);

      const hasMediumSize = bagsizelistData?.data?.some(item =>
        item.surpriseBagSize === "medium" || item.surpriseBagSize === "Medium"
      );
      setShowRecommendation(hasMediumSize)
    } else {
      ToastMessage("error", bagsizelistData?.message);
    }
  }, [bagsizelistData]);

  useEffect(() => {

    setBagsizeLoading(true)
    const parameter = {
      url: `${bagsize_list_api}${restaurantIdFK}`,
    };
    dispatch(getbagsize(parameter));


  }, [selectedCard]);



  
  useEffect(() => {
    const percentageValue = (Number(values.discount) / 100) * values.surpriseBagRetailPrice;
    values.surpriseBagSellingPrice = values.surpriseBagRetailPrice - parseFloat(percentageValue.toFixed(2));
    console.log("percentageValue", percentageValue)
  }, [values.surpriseBagRetailPrice, values.discount]);



  return (

    <div style={{ margin: "3%", marginTop: "30px" }}>
      {/* {BagsizeLoading ? (
        <div className="d-flex justify-content-center">
          <p>
            <Skeleton height={200} width={300} />
            <Skeleton
              height={200}
              width={300}
              style={{}}
            />
          </p>
        </div>
      ) : (
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue=""
          name="radio-buttons-group"
          onChange={handleradiochange}
        >
          {data?.map((item, index) => (
            <div
              key={index}
              className="size_container bigsize"
              onClick={handleradiochange}
              style={{ borderRadius: "10px", marginBottom: "10px" }}
            >
              <FormControlLabel
                value={item.surpriseBagSizeIdFK}
                control={
                  <Radio
                    sx={{
                      color: `var(--colorused)`,
                      "&.Mui-checked": {
                        color: `var(--colorused)`,
                      },
                    }}
                  />
                }
                label={
                  <div className="bag_size_label">
                    <p className="margin-0">{item.surpriseBagSize}</p>
                    {item.surpriseBagSize === "Medium" && (
                      <p className="medium_recommended_value">
                        Recommended for you
                      </p>
                    )}
                    <div className="text-align-end">
                      <h3
                        className="margin-0 bagPrice"
                        style={{ fontSize: "18px" }}
                      >
                        ₹{item.surpriseBagRetailPrice}
                      </h3>
                      <p className="margin-0">minimum price</p>
                      <p className="margin-0 align">
                        ₹{item.surpriseBagSellingPrice} price in app
                      </p>
                    </div>
                    
                  </div>
                }
              />
            </div>
          ))}
        </RadioGroup>
      )} */}





            <div className="m-b-10 m-t-5">
              <h5 className="overview_details_h">Retail Price  *</h5>

              <>
                <TextField
                  name="surpriseBagRetailPrice" 
                  disabled=""
                  label=""
                  placeholder=""
                  value={values.surpriseBagRetailPrice}
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{ minWidth: "250px", width:"100" }}
                  size="small"
                />
                {errors.surpriseBagRetailPrice && touched.surpriseBagRetailPrice ? (
                  <p className="form-error">{errors.surpriseBagRetailPrice}</p>
                ) : null}
              </>
            </div>

            <div className="m-b-10 m-t-5">
              <h5 className="overview_details_h">Discount in % *</h5>

              <>
                <TextField
                  name="discount" 
                  disabled=""
                  label=""
                  placeholder=""
                  value={values.discount}
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{ minWidth: "250px", width:"100" }}
                  size="small"
                />
                {errors.discount && touched.discount ? (
                  <p className="form-error">{errors.discount}</p>
                ) : null}
              </>
            </div>

            <div className="m-b-10 m-t-5">
              <h5 className="overview_details_h">Selling Price  *</h5>

              <>
                <TextField
                  name="surpriseBagSellingPrice" 
                  disabled
                  label=""
                  placeholder=""
                  value={values.surpriseBagSellingPrice}
                  variant="outlined"
                  fullWidth
                  autoComplete="off"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{ minWidth: "250px", width:"100" }}
                  size="small"
                />
                {errors.surpriseBagSellingPrice && touched.surpriseBagSellingPrice ? (
                  <p className="form-error">{errors.surpriseBagSellingPrice}</p>
                ) : null}
              </>
            </div>





    </div>
  );
};

export default BagSizeListComp;
