import * as Yup from "yup";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const loginschema = Yup.object().shape({
    email: Yup.string().matches(emailRegex, "Please enter valid email").required('This is required'),
    password: Yup.string().required('This is required').min(6, 'Password must be atleast 6 digits'),
});

export const sendemailshema = Yup.object().shape({
    email: Yup.string().matches(emailRegex, "Please enter valid email").required('This is required'),
});


export const signUpshema = Yup.object().shape({
    email: Yup.string().matches(emailRegex, "Please enter valid email").required('This is required'),
    fullname: Yup.string()
        .matches(/^[A-Za-z\s]+$/, "Name must only contain alphabets")
        .test("not-only-spaces", "Name must not be only spaces", (value) => value && value.trim() !== "")
        .required("This is required")
});

export const restaurantCreateshema = Yup.object().shape({
    restaurantName: Yup.string()
        .matches(/^(?!\s*$).+$/, "Must not be only spaces")
        .required('This field is required'),

    restaurantAddress: Yup.string().matches(/\S/, ("Must not be only spaces")).required('This is required'),
    restaurantOwnerName: Yup.string().matches(/^[A-Za-z\s]+$/, "Name must only contain alphabets")
        .test("not-only-spaces", "Name must not be only spaces", (value) => value && value.trim() !== "")
        .required('This is required'),
    restaurantOwnerEmail: Yup.string().matches(emailRegex, "Please enter valid email").required('This is required'),
    // restaurantLandlineNumber: Yup.number().typeError("Must be number"),
    restaurantLandlineNumber: Yup.string()
        .matches(/^[0-9]{10}$/, "Must be a valid 10-digit number"),
    // .required("Restaurant landline number is required"),
    restaurantLandlineNumberCountryCode: Yup.number().typeError("Must be number"),
    restaurantAddressLatitude: Yup.string(),
    restaurantAddressLongitude: Yup.string(),
    establishmentType: Yup.string().required('This is required'),
    cuisinesSpeacility: Yup.string().required('This is required'),
    operationDay: Yup.string().required('This is required'),

});


export const SetPasswordSchema = Yup.object().shape({
    email: Yup.string().matches(emailRegex, "Please enter valid email").required('This is required'),
    password: Yup.string()
        .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&+])[A-Za-z\d@$!%*#?&+]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one Special Character"
        )
        .required('This is required'),
    confirmPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match"
    ).required('This is required'),
});



export const vendorLoginSchema = Yup.object().shape({
    email: Yup.string().matches(emailRegex, "Please enter valid email").required('This is required'),
    password: Yup.string().required('This is required'),

});

export const vendorSendEmailForResetPasswordSchema = Yup.object().shape({
    email: Yup.string().matches(emailRegex, "Please enter valid email").required('This is required'),

});


export const documentUploadSchema = Yup.object().shape({
    bankIdFk: Yup.string().required('This field is required'),
    accountHolderName: Yup.string().matches(/^[A-Za-z\s]+$/, "Name must only contain alphabets")
        .test("not-only-spaces", "Name must not be only spaces", (value) => value && value.trim() !== "")
        .required('This field is required'),
    accountNumber: Yup.number().required('This field is required').typeError("Must be a number"),
    ifscCode: Yup.string().matches(/\S/, ("Must not be only spaces")).required('This field is required'),
    // fssaiLicence: Yup.mixed(),
    fssaiLicence: Yup.mixed().typeError("")
        .test('fssaiLicence', 'This field is required', function (value) {
            const { fssaiLicenceExpiryDate } = this.parent;
            if (fssaiLicenceExpiryDate && !value) {
                return false;
            }
            return true;
        }),
    // fssaiLicenceExpiryDate: Yup.string().required('This field is required'),
    fssaiLicenceExpiryDate: Yup.string()
        .test('expiry-date-required', 'This field is required', function (value) {
            const { fssaiLicence } = this.parent;
            if (fssaiLicence && !value) {
                return false;
            }
            return true;
        }),
    panCard: Yup.mixed().required('This field is required'),
    regularGstIn: Yup.mixed().required('This field is required'),
    branchName: Yup.string().matches(/^(?!.*\d)(?!\s*$).+$/, "Must not contain numbers and must not be only spaces").required('This field is required'),
    foodImage: Yup.mixed().required('This field is required'),
    restaurantLogo: Yup.mixed().required('This field is required'),
});


export const overviewUpdateSchema = Yup.object().shape({
    surpriseBagName: Yup.string().required('This field is required'),
    surpriseBagDescription: Yup.string().required('This field is required'),
    // surpriseBagSizeIdFk: Yup.string().required('This field is required'),

    discount: Yup.number()
    .required("Discount is required")
    .min(0, "Discount cannot be negative")
    .max(100, "Discount cannot be more than 100%")
    .typeError("Discount must be a number")
    .transform((value) => (isNaN(value) ? undefined : value)),

  surpriseBagRetailPrice: Yup.number()
    .typeError("Retail Price must be a number")
    .required("Retail Price is required")
    .min(0, "Retail Price must be greater than or equal to 0"),

  surpriseBagSellingPrice: Yup.number()
    .typeError("Selling Price must be a number")
    .required("Selling Price is required")
    .min(0, "Selling Price must be greater than or equal to 0"),


    dietaryType: Yup.string().required('This field is required'),
});



export const calenderUpdateSchema = Yup.object().shape({
    firstDay: Yup.string().required('This field is required'),
    lastDay: Yup.string().required('This field is required'),

});


export const calenderSchemaRequired = Yup.object().shape({
    firstDay: Yup.string().required('This field is required'),
    lastDay: Yup.string().required('This field is required'),
    startTime: Yup.string().required('This field is required'),
    endTime: Yup.string().required('This field is required'),
    dailyNumberOfSurpriseBag: Yup.string().required('This field is required'),
});


export const editSurpriseBagSchema = Yup.object().shape({
    pickupDay: Yup.string().required('This field is required'),
    dailyNumberOfSurpriseBag: Yup.string().required('This field is required'),
    startTime: Yup.string().required('This field is required'),
    endTime: Yup.string().required('This field is required'),
});

export const orderStatusSchema = Yup.object().shape({
    orderStatus: Yup.string().required('This field is required'),
});

export const addmoreShema = Yup.object().shape({
    surpriseBagCoverImage: Yup.mixed(),
    surpriseBagCategory: Yup.string().required('This field is required'),
    surpriseBagName: Yup.string().required('This field is required'),
    surpriseBagDescription: Yup.string().required('This field is required'),
    // surpriseBagSizeIdFk: Yup.string().required('This field is required'),
    dietaryType: Yup.string().required('This field is required'),
    surpriseBagEndDate: Yup.string().required('This field is required'),
    pickupSchedule: Yup.string().required('This field is required'),
    surpriseBagPackaging: Yup.string().matches(/\S/, ("Must not be only spaces")).required('This field is required'),
    surpriseBagPickupInstruction: Yup.string().matches(/\S/, ("Must not be only spaces")).required('This field is required'),
    surpriseBagStoringAndAllergens: Yup.string().matches(/\S/, ("Must not be only spaces")).required('This field is required'),
    discount: Yup.number()
    .required("Discount is required")
    .min(0, "Discount cannot be negative")
    .max(100, "Discount cannot be more than 100%")
    .typeError("Discount must be a number")
    .transform((value) => (isNaN(value) ? undefined : value)),

  surpriseBagRetailPrice: Yup.number()
    .typeError("Retail Price must be a number")
    .required("Retail Price is required")
    .min(0, "Retail Price must be greater than or equal to 0"),

  surpriseBagSellingPrice: Yup.number()
    .typeError("Selling Price must be a number")
    .required("Selling Price is required")
    .min(0, "Selling Price must be greater than or equal to 0"),
});


export const addnewmagicbagschema = Yup.object().shape({
    discount: Yup.number()
    .required("Discount is required")
    .min(0, "Discount cannot be negative")
    .max(100, "Discount cannot be more than 100%")
    .typeError("Discount must be a number")
    .transform((value) => (isNaN(value) ? undefined : value)),

  surpriseBagRetailPrice: Yup.number()
    .typeError("Retail Price must be a number")
    .required("Retail Price is required")
    .min(0, "Retail Price must be greater than or equal to 0"),

  surpriseBagSellingPrice: Yup.number()
    .typeError("Selling Price must be a number")
    .required("Selling Price is required")
    .min(0, "Selling Price must be greater than or equal to 0"),
});


export const addTicketShema = Yup.object().shape({
    file: Yup.mixed(),
    mobileNumber: Yup.string().matches(/\S/, ("Must not be only spaces")).required('This is required'),
    email: Yup.string().matches(emailRegex, "Please enter valid email").required('This is required'),
    userName: Yup.string().matches(/^[A-Za-z\s]+$/, "Name must only contain alphabets")
        .test("not-only-spaces", "Name must not be only spaces", (value) => value && value.trim() !== "")
        .required('This is required'),
    purpose: Yup.string().required('This is required'),
    message: Yup.string().matches(/\S/, ("Must not be only spaces")).required('This is required'),
});

export const ChangePasswordShema = Yup.object().shape({
    oldPassword: Yup.string().required("This is required"),
    newPassword: Yup.string()
        .matches(
            /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&+])[A-Za-z\d@$!%*#?&+]{8,}$/,
            "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one Special Character"
        )
        .required('This is required'),


    confirmNewPassword: Yup.string().oneOf(
        [Yup.ref("newPassword"), null],
        "Passwords must match"
    ).required('This is required'),

});