
export const ImageUrl =
  "https://pickntreat.s3.amazonaws.com/";


// dev

// export const baseURL = 'https://dev.api.pickntreat.in/'; 


// production

export const baseURL = 'https://prod.api.pickntreat.in/';   

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const postApiCallToken = async (url, data) => {
    const refreshToken = localStorage.getItem("refreshToken")
    
    const accessToken = localStorage.getItem('accessToken')
    
    const customHeaders = {
      token: accessToken,
      refreshtoken:refreshToken,
      timezone: timezone,
    };
    let response
    try {
      response = await baseURL.post(url, data, {
        headers: customHeaders,
      })
      
    } catch (error) {
      if (error.response.data.message === "Invalid authorization token") {
        localStorage.clear()
      }
      if (error.response.data.message === "Authorization token has expired") {
        localStorage.clear()
      }
    }
  
  
    return response
  }





  // export const resturantdetails_api = "restaurant/details/update";




export const signUp_api = baseURL + 'vendor/create';

export const otpverify_api = baseURL + 'vendor/verify/otp';

export const resturantdetails_api = baseURL + 'restaurant/details/update';


export const uploadimage_api = baseURL + 'restaurant/upload/image';

export const resturant_type_and_timing = baseURL + 'restaurant/type-and-timing/update';

export const get_cuisin = baseURL + 'specialty/specialty/getList';

export const get_mobile_otp = baseURL + 'restaurant/send/otp-owner-Mobile';

export const verify_owner_otp = baseURL + 'restaurant/verify/otp-owner-Mobile';

export const get_resturant_otp = baseURL + 'restaurant/send/otp-Mobile';

export const verify_resturant_otp = baseURL + 'restaurant/verify/otp-Mobile';

export const restaurant_registration = baseURL + 'restaurant/registration';

export const setPassword_api = baseURL + 'vendor/setPassword';

export const vendorLogin_api = baseURL + 'vendor/login';

export const documentUpload_api = baseURL + 'restaurant/document/upload/image';

export const bank_list_api = baseURL + 'get/bank-list';

export const bagsize_list_api = baseURL + 'vendor/get/surprise-bag-size/list/';

export const create_bag_api = baseURL + 'vendor/create/surpriseBag';

export const bagListfordashboard_api = baseURL + 'vendor/get/surprise-bag/list/byid/';

export const surprisebagDetails_api = baseURL + 'vendor/get/surprise-bag/history/getById/';

export const update_instruction_api = baseURL + 'vendor/update/surpriseBag/instruction/before/activating';

export const update_overview_api = baseURL + 'vendor/update/surpriseBag/before/activating';

export const update_schedule_api = baseURL + 'vendor/update/surpriseBag/pickup-schedule/before/activating';

export const bagsize_list_active_deactive = baseURL + 'admin/active-deactivate/surprise-bag-size';


export const update_calender_api = baseURL + 'vendor/create/surpriseBag/special-day';

export const orderList_api = baseURL + 'vendor/get/order/list/';


export const active_surprisebag_api = baseURL + 'vendor/active/surprise-bag/';

// export const change_orderStatus_api = baseURL + 'vendor/confirm/order-delivered';

export const change_orderStatus_api = baseURL + 'vendor/update/order-status';

export const edit_surprise_bag_api = baseURL + 'vendor/edit/surpriseBag/after-activate';

export const edit_coverPhoto_api = baseURL + 'vendor/update-surprise-bag-cover-image';

export const edit_restaurantLogo_api = baseURL + 'vendor/update-restaurant-logo';

export const send_emailforReset_api = baseURL + 'vendor/send/resetPassword/email';

export const resetPassword_api = baseURL + 'vendor/resetPassword';

export const getDocument_status_api = baseURL + 'vendor/check/document-status';

export const inActive_surprisebag_api = baseURL + 'vendor/in-active/surprise-bag/';

export const addMore_surprisebag_api = baseURL + 'vendor/create/more/surpriseBag';

export const support_create_api = baseURL + 'vendor/support-ticket/create';

export const support_listVendor_api = baseURL + 'vendor/support-ticket-list/';

export const order_details_api = baseURL + 'vendor/get/order-history/by/order-id';

export const get_setting_api = baseURL + "admin/get/setting/data";

export const faqList_api = baseURL + "admin/get/faq-list";

export const document_viewlist_api = baseURL + "restaurant/document/history/getById/";

export const document_edit_api = baseURL + "restaurant/document/edit";


export const verifyPrivateCode_api = baseURL + "vendor/check/order-private-code";

export const reservationList_api = baseURL + "vendor/get/un-delivered/order/list/";

export const getSpecialday_api = baseURL + "vendor/get/surpriseBag/special-day/";

export const ExcelDownloader_api = baseURL+"vendor/get/order/list/excel-download/"

export const GetDetailById=baseURL+"admin/get/support-ticket/"

export const submitTicketChatData_api = baseURL+"admin/support-ticket/create/chat"

export const TicketChatData_api = baseURL+"submit-ticket-chat-data"

export const GetTicketChatData_api = baseURL +"get-ticket-chat-data"
// export const trerty_api = baseURL+"https://fetch.indya.ai/get-all-aws-services-list"


export const faqList_api_vender = baseURL + "vendor/get/faq-list"

export const get_pickupwindow_data = baseURL + "vendor/get/surpriseBag/pickup-schedule/after-activate"

export const Change_password_api = baseURL + "vendor/change/login-password"

export const dashboard_revenue_api = baseURL + "revenue-dashboard/vendor/revenue-dashboard"

export const get_Notification_api=baseURL+"get/notification/vendor/"

export const read_message_api = baseURL+"notification/read/"

export const unreadCount_api = baseURL+"vendor/notification/unread/count/"


export const image_support_chat=baseURL+"support/chat/image-video-upload"


export const vendor_empty_device_token=baseURL+"vendor/empty/device-token/"