import axios from 'axios';
// const headers = {
//   Accept: 'application/json',
//   'Content-Type': 'application/json',
//   timezone: RNLocalize.getTimeZone(),
// };
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

function getFormattedTimezoneOffset() {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const absOffset = Math.abs(offset);
  const hours = Math.floor(absOffset / 60);
  const minutes = absOffset % 60;
  const sign = offset > 0 ? '-' : '+';

  return `${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
}


async function putNetworkCall(params, action) {
  let response;

  try {
    response = await axios.put(params?.data?.url, params?.data, {
      headers: customHeaders(),
    });
  } catch (error) {
    if (
      error.response.data.message === "Invalid authorization token Admin" || error.response.data.statusCode === 405 ||
      error.response.data.statusCode === 406
    ) {
      localStorage.clear();
      window.location.href = "/";
    } else if (
      error.response.data.message === "Authorization token is missing!"
    ) {
      localStorage.clear();
      window.location.href = "/";
    } else if (error.response.data.statusCode === 401) {
      await handleTokenRefresh();

      response = await axios.put(params?.data?.url, params?.data, {
        headers: customHeaders(),
      });
      localStorage.clear();
      window.location.href = "/";
    } else {
      console.error(error.message, "error");
      return error.message;
    }
  }

  return response.data;
}



const customHeaders = () => ({
  token: localStorage.getItem('vendorAccessToken'),
  refreshToken: localStorage.getItem('vendorRefreshToken'),
  deviceToken: localStorage.getItem('device_token'),
  timezone: timezone,
  Offset: getFormattedTimezoneOffset(),

});

const customMultipartHeaders = () => ({
  token: localStorage.getItem('vendorAccessToken'),
  refreshToken: localStorage.getItem('vendorRefreshToken'),
  deviceToken: localStorage.getItem('device_token'),
  timezone: timezone,
  Offset: getFormattedTimezoneOffset(),
  "Content-type": "multipart/form-data",
});

async function postNetworkCall(params, action) {

  let response;

  try {
    response = await axios.post(params?.data?.url, params?.data, {
      headers: customHeaders(),
    });
  } catch (error) {
   
    if (error.response.data.message === 'Invalid authorization token Vendor' || error.response.data.statusCode === 406 ||  error.response.data.statusCode === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if (error.response.data.message === 'Authorization token is missing!') {
      localStorage.clear();
      window.location.href = '/';
    } else if (error.response.data.statusCode === 405) {
      await handleTokenRefresh();

      response = await axios.post(params?.data?.url, params?.data, {
        headers: customHeaders(),
      });
    } else {
      console.error(error.message, 'error');
      return error.message;
    }
  }

  return response.data;
}


async function postexcelNetworkCall(params) {
  let response;

  try {
    response = await axios.post(params?.data?.url, params?.data, {
      headers: customHeaders(),
      responseType: 'blob', // Handle binary data
    });
  } catch (error) {
    if (error.response.data.message === 'Invalid authorization token Vendor' || error.response.data.statusCode === 406 ||  error.response.data.statusCode === 401) {
      localStorage.clear();
      window.location.href = '/';
    } else if (error.response.data.message === 'Authorization token is missing!') {
      localStorage.clear();
      window.location.href = '/';
    } else if (error.response.data.statusCode === 405) {
      await handleTokenRefresh();

      response = await axios.post(params?.data?.url, params?.data, {
        headers: customHeaders(),
        responseType: 'blob', // Handle binary data
      });
    } else {
      console.error(error.message, 'error');
      throw error; // Throw error instead of returning it
    }
  }

  return response.data; // This should now be binary data
}

async function postNetworkcreateCall(params, action) {
  let response;
  try {
    response = await axios.post(params?.data?.url, params?.data?.data, {
      headers: customMultipartHeaders(),
    });
  } catch (error) {
    if (error.response.data.message === 'Invalid authorization token Admin' || error.response.data.statusCode === 406) {
      localStorage.clear();
      window.location.href = '/';
    } else if (error.response.data.message === 'Authorization token is missing!') {
      localStorage.clear();
      window.location.href = '/';
    } else if (error.response.data.statusCode === 405) {
      await handleTokenRefresh();

      response = await axios.post(params?.data?.url, params?.data, {
        headers: customHeaders(),
      });
    } else {
      console.error(error.message, 'error');
      return error.message;
    }
  }

  return response.data;
}

async function handleTokenRefresh() {
  const result = await axios.post('https://dev.api.pickntreat.in/generate/vendor/access-token/from/refresh-token', {
    refreshToken: localStorage.getItem('vendorRefreshToken'),
  });

  localStorage.setItem('vendorAccessToken', result.data.accessToken);
}


async function getNetworkCall(params, action) {
  let response;

  try {
    response = await axios.get(params?.data?.url, {
      headers: customHeaders(),
    });
  } catch (error) {
    if (error.response.data.message === 'Invalid authorization token Admin' || error.response.data.statusCode === 406) {
      localStorage.clear();
      window.location.href = '/';
    } else if (error.response.data.message === 'Authorization token is missing!') {
      localStorage.clear();
      window.location.href = '/';
    } else if (error.response.data.statusCode === 405) {
      await handleTokenRefresh();

      response = await axios.get(params?.data?.url, {
        headers: customHeaders(),
      });
    } else {
      console.error(error.message, 'error');
      return error.message;
    }
  }

  return response.data;
}

export {
  getNetworkCall,
  postNetworkCall,
  postNetworkcreateCall,
  postexcelNetworkCall,
  putNetworkCall
};
