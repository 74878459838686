// import React, { useEffect, useState } from 'react'
// import "./index.css"
// import CloseIcon from '@mui/icons-material/Close';
// import Visibility from '@mui/icons-material/Visibility';
// import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import { useFormik } from "formik";
// import { useNavigate } from 'react-router-dom';
// import { CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, TextField } from '@mui/material'
// import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import InputField from '../../components/input';
// import { useDispatch, useSelector } from 'react-redux';
// import { vendorLoginaction } from '../../redux/actions';
// import { vendorLogin_api } from '../../API/baseUrl';
// import { vendorLoginSchema } from '../../utils/schema';
// import ToastMessage from '../../utils/ToastMessage';
// import { getTokenfun, onMessageListener } from '../../firebase';
// import logo from '../../images/Full-logo.svg'
// import Logo from './logo';

// const Login = () => {
//     const navigate = useNavigate()
//     const [showPassword, setShowPassword] = useState(false)
//     const [loading, setLoading] = useState(false)
//     const dispatch = useDispatch();
//     const { vendorloginData } = useSelector((state) => state?.vendorLoginreduser || {});
//     const auth = localStorage.getItem('auth');

//     const [show, setShow] = useState(false);
//     const [notification, setNotification] = useState({ title: '', body: '' });
//     const [isTokenFound, setTokenFound] = useState(false);

//     getTokenfun(setTokenFound);

//     onMessageListener().then(payload => {
//         setShow(true);
//         setNotification({ title: payload.notification.title, body: payload.notification.body })
//         console.log(payload);
//     }).catch(err => console.log('failed: ', err));

//     useEffect(() => {
//         const auth = localStorage.getItem("auth");
//         if (auth) {
//             navigate("/dashboard/app");
//         } else {
//             navigate("/");
//         }
//     }, []);

//     const initialValues = {
//         email: "",
//         password: "",
//     }

//     const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } =
//         useFormik({
//             initialValues,
//             validationSchema: vendorLoginSchema,
//             onSubmit: () => submitForm()
//         });

//     useEffect(() => {
//         if (vendorloginData?.status) {
//             setLoading(false)
//             ToastMessage('success', vendorloginData?.message);
//             localStorage.setItem('vendorIdFk', vendorloginData?.vendorIdFk);
//             localStorage.setItem('auth', true);
//             localStorage.setItem('restaurantTypeIdFK', vendorloginData?.restaurantTypeIdFK);
//             localStorage.setItem('restaurantIdFK', vendorloginData?.restaurantIdFK);
//             localStorage.setItem('vendorAccessToken', vendorloginData?.vendorAccessToken);
//             localStorage.setItem('vendorRefreshToken', vendorloginData?.vendorRefreshTen);
//             localStorage.setItem('documentStatus', vendorloginData?.documentStatus);
//             localStorage.setItem('documentupload', vendorloginData?.isDocumentUpload);
//             localStorage.setItem('isSingleSurpriseBagCreated', vendorloginData?.isSingleSurpriseBagCreated);
//             localStorage.setItem('restaurantName', vendorloginData?.restaurantName);
//             localStorage.setItem('restaurantLogo', vendorloginData?.restaurantLogo);
//             localStorage.setItem('restaurantFoodImage', vendorloginData?.restaurantFoodImage);
//             console.log(vendorloginData?.restaurantFoodImage, "restaurantFoodImage");
//             if (vendorloginData?.isDocumentUpload === 0) {

//                 navigate("/dashboard/documents");
//             }
//             else if (vendorloginData?.documentStatus == 2) {
//                 navigate("/dashboard/documents");
//             }
//             else if (vendorloginData?.isSingleSurpriseBagCreated === 0 && vendorloginData?.documentStatus == 1) {

//                 navigate("surpriceBag/createSurprisebag");
//             }
//             else {

//                 navigate("/dashboard/app");
//             }

//         } else {
//             setLoading(false)
//             ToastMessage('error', vendorloginData?.message);
//         }
//     }, [vendorloginData]);

//     const handleClickShowPassword = () => setShowPassword((show) => !show);

//     const submitForm = async () => {
//         setLoading(true)
//         const parameter = {
//             email: values.email,
//             password: values.password,
//             url: vendorLogin_api,
//         };
//         dispatch(vendorLoginaction(parameter));
//     }

//     const handleformSubmit = (e) => {
//         e.preventDefault()
//         console.log(errors, values)
//         handleSubmit()
//     }

//     const handlesignup = () => {
//         navigate("/signup")
//     }

//     const handleforgetPassword = () => {
//         navigate("/forgetPassword")
//     }

//     return (
//         <div className='login-container'>
//             <form onSubmit={handleformSubmit} className='login-container_fluid'>
//             <Logo/>

//                 <div>
//                     <InputField label="Email" placeholder="Email@gmail.com" name="email" value={values.email} type="email"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                     />
//                     {errors.email && touched.email ? (
//                         <p className="form-error">{errors.email}</p>
//                     ) : null}

//                 </div>

//                 <FormControl sx={{ width: '100%', mt: 2, }} variant="outlined">

//                     <InputLabel className='label_sign_password' htmlFor="outlined-adornment-password">Password</InputLabel>
//                     <OutlinedInput
//                         className='input_sign_password'
//                         id="password"
//                         size='small'
//                         autoComplete="new-password"
//                         type={showPassword ? 'text' : 'password'}
//                         name="password"
//                         value={values.password}
//                         placeholder="Password"
//                         onChange={handleChange}
//                         onBlur={handleBlur}
//                         endAdornment={
//                             <InputAdornment position="end">
//                                 <IconButton
//                                     aria-label="toggle password visibility"
//                                     onClick={handleClickShowPassword}
//                                     edge="end"
//                                 >
//                                     {!showPassword ? <VisibilityOff /> : <Visibility />}
//                                 </IconButton>
//                             </InputAdornment>
//                         }
//                         label="Password"
//                     />
//                     {touched.password && errors.password ? (
//                         <div className="form-error">{errors.password}</div>
//                     ) : null}
//                 </FormControl>

//                 <button type='submit' className='login-btn'>
//                     {loading ? (
//                         <CircularProgress size={24} style={{ color: "white" }} />
//                     ) : (
//                         "Login"
//                     )}
//                 </button>
//                 <p onClick={handleforgetPassword} className='Forget_password_text'>Forget Password ?</p>

//                 <p className='creatperatext' >New to Pick'n'Treat ?<button className='createaccount_btn' onClick={handlesignup}>Create account</button></p>

//             </form>

//         </div>
//     )
// }

// export default Login

import React, { useEffect, useState } from "react";
import "./index.css";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import InputField from "../../components/input";
import { useDispatch, useSelector } from "react-redux";
import { removeDataFromReducer, vendorLoginaction } from "../../redux/actions";
import { vendorLogin_api } from "../../API/baseUrl";
import { vendorLoginSchema } from "../../utils/schema";
import ToastMessage from "../../utils/ToastMessage";
import { getTokenfun, onMessageListener } from "../../firebase";
import Logo from "./logo";

const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { vendorloginData } = useSelector(
    (state) => state?.vendorLoginreduser || {}
  );
  const auth = localStorage.getItem("auth");

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);

  getTokenfun(setTokenFound);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      console.log(payload);
    })
    .catch((err) => console.log("failed: ", err));

  useEffect(() => {
    const auth = localStorage.getItem("auth");
    if (auth) {
      navigate("/dashboard/app");
    } else {
      navigate("/");
    }
  }, []);

  const initialValues = {
    email: "",
    password: "",
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema: vendorLoginSchema,
    onSubmit: () => submitForm(),
  });

  useEffect(() => {
    if (vendorloginData?.status) {
      setLoading(false);
      ToastMessage("success", vendorloginData?.message);
      localStorage.setItem("vendorIdFk", vendorloginData?.vendorIdFk);
      localStorage.setItem("auth", true);
      localStorage.setItem(
        "restaurantTypeIdFK",
        vendorloginData?.restaurantTypeIdFK
      );
      localStorage.setItem("restaurantIdFK", vendorloginData?.restaurantIdFK);
      localStorage.setItem(
        "vendorAccessToken",
        vendorloginData?.vendorAccessToken
      );
      localStorage.setItem(
        "vendorRefreshToken",
        vendorloginData?.vendorRefreshToken
      );
      localStorage.setItem("documentStatus", vendorloginData?.documentStatus);
      localStorage.setItem("documentupload", vendorloginData?.isDocumentUpload);
      localStorage.setItem(
        "isSingleSurpriseBagCreated",
        vendorloginData?.isSingleSurpriseBagCreated
      ); 
      localStorage.setItem("restaurantName", vendorloginData?.restaurantName);
      localStorage.setItem("restaurantLogo", vendorloginData?.restaurantLogo);
      localStorage.setItem(
        "restaurantFoodImage",
        vendorloginData?.restaurantFoodImage
      );
      console.log(vendorloginData?.restaurantFoodImage, "restaurantFoodImage");
      if (vendorloginData?.isDocumentUpload === 0) {
        navigate("/dashboard/documents");
      } else if (vendorloginData?.documentStatus == 2) {
        navigate("/dashboard/documents");
      } else if (
        vendorloginData?.isSingleSurpriseBagCreated === 0 &&
        vendorloginData?.documentStatus == 1
      ) {
        navigate("surpriceBag/createSurprisebag");
      } else {
        navigate("/dashboard/app");
      }
    } else {
      setLoading(false);
      ToastMessage("error", vendorloginData?.message);
    }
  }, [vendorloginData]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const submitForm = async () => {
    setLoading(true);
    const parameter = {
      email: values.email,
      password: values.password,
      url: vendorLogin_api,
    };
    dispatch(vendorLoginaction(parameter));
  };

  const handleformSubmit = (e) => {
    e.preventDefault();
    console.log(errors, values);
    handleSubmit();
  };

  const handlesignup = () => {
    dispatch(removeDataFromReducer("VENDOR_LOGIN_RESET"));
    navigate("/signup");
  };

  const handleforgetPassword = () => {
    dispatch(removeDataFromReducer("VENDOR_LOGIN_RESET"));
    dispatch(removeDataFromReducer("FORGET_PASSWORD_RESET"));
    navigate("/forgetPassword");
  };

  return (
    <div className="login-container">
     

      <form onSubmit={handleformSubmit} className="login-container_fluid">
        <div className="login-div"></div>
        <Logo />
        <div>
          <InputField
            label="Email"
            placeholder="Enter your email"
            name="email"
            value={values.email}
            type="email"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.email && touched.email ? (
            <p className="form-error">{errors.email}</p>
          ) : null}
        </div>

        <FormControl sx={{ width: "100%", mt: 2 }} variant="outlined">
          <InputLabel
            className="label_sign_password"
            htmlFor="outlined-adornment-password"
          >
            Password
          </InputLabel>
          <OutlinedInput
            className="input_sign_password"
            id="password"
            size="small"
            autoComplete="new-password"
            type={showPassword ? "text" : "password"}
            name="password"
            value={values.password}
            placeholder="Enter your password"
            onChange={handleChange}
            onBlur={handleBlur}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {!showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
          {touched.password && errors.password ? (
            <div className="form-error">{errors.password}</div>
          ) : null}
        </FormControl>

        <button type="submit" className="login-btn">
          {loading ? (
            <CircularProgress size={24} style={{ color: "white" }} />
          ) : (
            "Login"
          )}
        </button>
        <p onClick={handleforgetPassword} className="Forget_password_text">
          Forget Password?
        </p>

        <p className="creatperatext">
          New to Pick'n'Treat?
          <button className="createaccount_btn" onClick={handlesignup}>
            Create Account
          </button>
        </p>
      </form>
    </div>
  );
};

export default Login;
